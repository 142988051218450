import styles from "../../components/Stylesheet";
import React, { useRef } from "react";
import {
  Pressable,
  SafeAreaView,
  View,
  Text,
  TextInput,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import * as Yup from 'yup'

const am_HIPAA = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const nav = useNavigation();
  const sectionName = "HIPAA_AUTH";
  const nextSectionRoute = "LivingWill";
  const formRef = useRef();

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <Text style={styles.sectionHeader}>
          HIPPA Privacy Authorization Form
        </Text>
        <Formik
          innerRef={formRef}
          initialValues={{
            firstName: form[sectionName] ? form[sectionName].firstName : "",
            middleInitial: form[sectionName] ? form[sectionName].middleInitial : "",
            lastName: form[sectionName] ? form[sectionName].lastName : "",
            DOB: form[sectionName] ? form[sectionName].DOB : "",
            exceptions: form[sectionName] ? form[sectionName].DOB : "",
            signature: form[sectionName] ? form[sectionName].signature : "",
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  firstName: values.firstName,
                  middleInitial: values.middleInitial,
                  lastName: values.lastName,
                  DOB: values.DOB,
                  exceptions: values.exceptions,
                  signature: values.signature,
                },
              })
            )
              dispatch(
                saveAndContinue(store.getState().application.form)
              )
              navigation.navigate(nextSectionRoute);
            
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup
              .string()
              .required("First name is required")
              .min(2, ({min}) => `First name should be at least ${min} characters`)
              .max(10, ({max}) => `First name should be shorter than ${max} characters`),
            middleInitial: Yup
            .string()
              .required("Middle name is required")
              .min(2, ({min}) => `Middle name should be at least ${min} characters`)
              .max(10, ({max}) => `Middle name should be shorter than ${max} characters`),
            lastName: Yup
            .string()
              .required("Last name is required")
              .min(2, ({min}) => `Last name should be at least ${min} characters`)
              .max(25, ({max}) => `Last name should be shorter than ${max} characters`),
            DOB: Yup
              .string()
              .required('Date of Birth is required'),
            exceptions: Yup
            .string(),
            signature: Yup
            .string()
            .required("Signature is required")
            .min(5, ({min}) => `Signature should be at least ${min} characters`)
            .max(40, ({max}) => `Signature should be shorter than ${max} characters`),
          })} 
        >
          {({ handleChange, handleBlur, submitForm, setFieldValue, values, errors, touched, isValid }) => (
            <>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>First Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("firstName")}
                  onBlur={handleBlur("firstName")}
                  value={values.firstName}
                />
                {(errors.firstName && touched.firstName) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.firstName}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Middle Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("middleInitial")}
                  onBlur={handleBlur("middleInitial")}
                  value={values.middleInitial}
                />
                {(errors.middleInitial && touched.middleInitial) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.middleInitial}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Last Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("lastName")}
                  onBlur={handleBlur("lastName")}
                  value={values.lastName}
                />
                {(errors.lastName && touched.lastName) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.lastName}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Date of Birth</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("DOB")}
                  onBlur={event => {
                    const dateObj = new Date(values.DOB)
                    let monthString = ""
                    if (dateObj.getMonth() === 0) {
                      monthString = "January"
                    } else if (dateObj.getMonth() === 1) {
                      monthString = "February"
                    } else if (dateObj.getMonth() === 2) {
                      monthString = "March"
                    } else if (dateObj.getMonth() === 3) {
                      monthString = "April"
                    } else if (dateObj.getMonth() === 4) {
                      monthString = "May"
                    } else if (dateObj.getMonth() === 5) {
                      monthString = "June"
                    } else if (dateObj.getMonth() === 6) {
                      monthString = "July"
                    } else if (dateObj.getMonth() === 7) {
                      monthString = "August"
                    } else if (dateObj.getMonth() === 8) {
                      monthString = "September"
                    } else if (dateObj.getMonth() === 9) {
                      monthString = "October"
                    } else if (dateObj.getMonth() === 10) {
                      monthString = "November"
                    } else if (dateObj.getMonth() === 11) {
                      monthString = "December"
                    }
                  
                    const formatted = `${monthString} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
                    if (formatted !== " NaN, NaN") {
                      setFieldValue('DOB', formatted)
                    } else {
                      setFieldValue('DOB', undefined)
                    }
                    }
                  }
                  value={values.DOB}
                />
                {(errors.DOB && touched.DOB) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.DOB}</Text>
                }
              </View>
              <View>
                <Text style={styles.sectionDescription}>
                  1. Authorization I authorize the organization Institute on
                  Healthcare Directives (IHCD) and it’s affiliated companies,
                  including members of its professional team, supporting staff,
                  and the healthcare professionals enrolled in its training
                  program for Advance Care Planning counseling, to use and to
                  disclose the protected health information (PHI) described
                  below to any individual healthcare provider or institution or
                  others, if their intent is for others to learn the wishes that
                  I expressed in my Living Will and/or my Advance Directive or
                  directly related treatment or any other for other purposes as
                  I may agree to either below or add in a separate consent form.{" "}
                  {"\n"}2. Effective Period This authorization for release of
                  information begins immediately upon my signing this form.{" "}
                  {"\n"}3.Extent of Authorization I authorize the release of my
                  complete health record (including records relating to mental
                  healthcare, communicable diseases, HIV or AIDS, and treatment
                  of alcohol or drug abuse) with the exceptions noted below. If
                  you have no exceptions to the list, the word "None” has been
                  pre-filled for you in the box for you. If you have exceptions,
                  please write them in the box below:
                </Text>
              </View>
              <View style={styles.fieldInputArea}>
                <TextInput
                  placeholder={"none"}
                  style={styles.textInput}
                  onChangeText={handleChange("exceptions")}
                  onBlur={handleBlur("exceptions")}
                  value={values.exceptions}
                />
              </View>
              <Text style={styles.sectionDescription}>
                4. This medical information may be used by the person
                (recipient) I authorize to receive this information for medical
                treatment or consultation, billing or claims payment, or other
                purposes as I may direct. {"\n"}5. This authorization shall be
                in force and effect until I revoke it. This consent is durable
                if I lose capacity and did not previously revoke it. {"\n"}6.  I
                understand that I have the right to revoke this authorization,
                in writing, at any time. I understand that a revocation is not
                effective to the extent that any person or entity has already
                acted in reliance on my authorization or if my authorization was
                obtained as a condition of obtaining insurance coverage and the
                insurer has a legal right to contest a claim. {"\n"}7.  I
                understand that my treatment, payment, enrollment or eligibility
                for benefits will not be conditioned on whether or not I sign
                this authorization.
                {"\n"}8.  I understand that information used or disclosed
                pursuant to this authorization may be disclosed by the recipient
                and may no longer be protected by federal or state law.
              </Text>
              <View style={styles.textAreaInputArea}>
                <Text style={styles.fieldHeading}>
                  Signature of Patient or Healthcare Agent
                </Text>
                <TextInput
                  multiline={true}
                  numberOfLines={6}
                  style={styles.textInput}
                  onChangeText={handleChange("signature")}
                  onBlur={handleBlur("signature")}
                  value={values.signature}
                />
                {(errors.signature && touched.signature) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.signature}</Text>
                }
              </View>
              <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>I Attest</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          firstName: values.firstName,
                          middleInitial: values.middleInitial,
                          lastName: values.lastName,
                          DOB: values.DOB,
                          exceptions: values.exceptions,
                          signature: values.signature,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default am_HIPAA;
