import styles from "../../components/Stylesheet";
import React, { useRef, useState } from "react";
import {
  Pressable,
  SafeAreaView,
  Text,
  TextInput,
  View,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import * as Yup from 'yup'
import { Picker } from '@react-native-community/picker'

const an_LivingWill = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const nav = useNavigation();
  const sectionName = "LIVING_WILL";
  const nextSectionRoute = "OtherDocuments";
  const formRef = useRef();
  const [localStateLivingWill, setLocalStateLivingWill] = useState(
    form[sectionName] ? form[sectionName].livingWill : null
  );
  const [localStateAdvanceDirective, setLocalStateAdvanceDirective] = useState(
    form[sectionName] ? form[sectionName].advanceDirective : null
  );
  const [localStateOrganDonor, setLocalStateOrganDonor] = useState(
    form[sectionName] ? form[sectionName].organDonor : null
  );
  const [localStateDNR, setLocalStateDNR] = useState(
    form[sectionName] ? form[sectionName].dnr : null
  );
  const [localStatePOLST, setLocalStatePOLST] = useState(
    form[sectionName] ? form[sectionName].polst : null
  );
  const [religion, setReligion] = useState(
    form[sectionName] && form[sectionName].religion ? form[sectionName].religion : "Please Select..."
    )

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Healthcare Directive or Living Will</Text>
          <Text style={styles.sectionDescription}>
          What documents do you already have and what would you like more information about?          </Text>
        </View>
        <Formik
          innerRef={formRef}
          initialValues={{
            livingWill: form[sectionName] ? form[sectionName].livingWill : null,
            advanceDirective: form[sectionName]
              ? form[sectionName].advanceDirective
              : null,
            organDonor: form[sectionName] ? form[sectionName].organDonor : null,
            dnr: form[sectionName] ? form[sectionName].dnr : null,
            polst: form[sectionName] ? form[sectionName].polst : null,
            religion: form[sectionName] ? form[sectionName].religion : null,            
            religiousInfo: form[sectionName] ? form[sectionName].religiousInfo : "",
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  livingWill: values.livingWill,
                  advanceDirective: values.advanceDirective,
                  organDonor: values.organDonor,
                  dnr: values.dnr,
                  polst: values.polst,
                  religion: values.religion,
                  religiousInfo: values.religiousInfo,
                },
              })
            );
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
          validationSchema={Yup.object().shape({
            livingWill: Yup
            .mixed().oneOf([true, false])
            .required('You must answer this question to continue'),
            advanceDirective: Yup
            .mixed().oneOf([true, false])
            .required('You must answer this question to continue'),
            organDonor: Yup
            .mixed().oneOf([true, false])
            .required('You must answer this question to continue'),
            dnr: Yup
            .mixed().oneOf([true, false, 'TBD'])
            .required('You must answer this question to continue'),
            polst: Yup
            .mixed().oneOf([true, false])
            .required('You must answer this question to continue'),
            religion: Yup
            .string(),
            religiousInfo: Yup
            .string()
            })}
        >
          {({
             handleChange,
             handleBlur,
             submitForm,
             setFieldValue,
             values,
             errors,
             touched,
             isValid,
          }) => (
            <>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Do you have a living will?
                </Text>
              </View>
              <View style={styles.binaryInput}>
                <Pressable
                  disabled={localStateLivingWill === true}
                  style={
                    localStateLivingWill === false || localStateLivingWill === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("livingWill", true);
                    setLocalStateLivingWill(true);
                  }}
                >
                  <Text
                    style={
                      localStateLivingWill === false || localStateLivingWill === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    Yes
                  </Text>
                </Pressable>
                <Pressable
                  disabled={localStateLivingWill === false}
                  style={
                    localStateLivingWill === true || localStateLivingWill === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("livingWill", false);
                    setLocalStateLivingWill(false);
                  }}
                >
                  <Text
                    style={
                      localStateLivingWill === true || localStateLivingWill === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    No
                  </Text>
                </Pressable>
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Would you like information about creating a living will or
                  advance directive?
                </Text>
              </View>
              <View style={styles.binaryInput}>
                <Pressable
                  disabled={localStateAdvanceDirective === true}
                  style={
                    localStateAdvanceDirective === false || localStateAdvanceDirective === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("advanceDirective", true);
                    setLocalStateAdvanceDirective(true);
                  }}
                >
                  <Text
                    style={
                      localStateAdvanceDirective === false || localStateAdvanceDirective === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    Yes
                  </Text>
                </Pressable>
                <Pressable
                  disabled={localStateAdvanceDirective === false}
                  style={
                    localStateAdvanceDirective === true|| localStateAdvanceDirective === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("advanceDirective", false);
                    setLocalStateAdvanceDirective(false);
                  }}
                >
                  <Text
                    style={
                      localStateAdvanceDirective === true  || localStateAdvanceDirective === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    No
                  </Text>
                </Pressable>
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Are you an organ donor?</Text>
              </View>
              <View style={styles.binaryInput}>
                <Pressable
                  disabled={localStateOrganDonor === true}
                  style={
                    localStateOrganDonor === false ||
                    localStateOrganDonor === "TBD" ||
                    localStateOrganDonor === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("organDonor", true);
                    setLocalStateOrganDonor(true);
                  }}
                >
                  <Text
                    style={
                      localStateOrganDonor === false ||
                      localStateOrganDonor === "TBD" ||
                      localStateOrganDonor === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    Yes
                  </Text>
                </Pressable>
                <Pressable
                  disabled={localStateOrganDonor === false}
                  style={
                    localStateOrganDonor === true ||
                    localStateOrganDonor === "TBD" ||
                    localStateOrganDonor === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("organDonor", false);
                    setLocalStateOrganDonor(false);
                  }}
                >
                  <Text
                    style={
                      localStateOrganDonor === true ||
                      localStateOrganDonor === "TBD" ||
                      localStateOrganDonor === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    No
                  </Text>
                </Pressable>
                <Pressable
                  disabled={localStateOrganDonor === "TBD"}
                  style={
                    localStateOrganDonor === true ||
                    localStateOrganDonor === false ||
                    localStateOrganDonor === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("organDonor", "TBD");
                    setLocalStateOrganDonor("TBD");
                  }}
                >
                  <Text
                    style={
                      localStateOrganDonor === true ||
                      localStateOrganDonor === false ||
                      localStateOrganDonor === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    TBD
                  </Text>
                </Pressable>
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Do you know what a Do Not Resuscitate (DNR) order is?{" "}
                </Text>
              </View>
              <View style={styles.binaryInput}>
                <Pressable
                  disabled={localStateDNR === true}
                  style={
                    localStateDNR === false || localStateDNR === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("dnr", true);
                    setLocalStateDNR(true);
                  }}
                >
                  <Text
                    style={
                      localStateDNR === false || localStateDNR === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    Yes
                  </Text>
                </Pressable>
                <Pressable
                  disabled={localStateDNR === "false"}
                  style={
                    localStateDNR === true || localStateDNR === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("dnr", false);
                    setLocalStateDNR(false);
                  }}
                >
                  <Text
                    style={
                      localStateDNR === true || localStateDNR === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    No
                  </Text>
                </Pressable>
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Do you know what Physician’s Order for Life Sustaining
                  Treatment (POLST) is?
                </Text>
              </View>
              <View style={styles.binaryInput}>
                <Pressable
                  disabled={localStatePOLST === true}
                  style={
                    localStatePOLST === false || localStatePOLST === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("polst", true);
                    setLocalStatePOLST(true);
                  }}
                >
                  <Text
                    style={
                      localStatePOLST === false || localStatePOLST === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    Yes
                  </Text>
                </Pressable>
                <Pressable
                  disabled={localStatePOLST === false}
                  style={
                    localStatePOLST === true || localStatePOLST === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("polst", false);
                    setLocalStatePOLST(false);
                  }}
                >
                  <Text
                    style={
                      localStatePOLST === true || localStatePOLST === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    No
                  </Text>
                </Pressable>
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Please indicate your religious tradition:
                </Text>
                  <Picker
                    selectedValue={religion}
                    mode="dropdown"
                    style={{ height: 100, width: 200 }}
                    onValueChange={(itemValue, itemIndex) => {
                      setReligion(itemValue)
                      setFieldValue('religion', itemValue)
                    }}
                  >
                    <Picker.Item label="Please Select..." value="Please Select..." />
                    <Picker.Item label="Christianity" value="Christianity" />
                    <Picker.Item label="Islam" value="Islam" />
                    <Picker.Item label="Hinduism" value="Hinduism" />
                    <Picker.Item label="Buddhism" value="Buddhism" />
                    <Picker.Item label="Taoism" value="Taoism" />
                    <Picker.Item label="Shinto" value="Shinto" />
                    <Picker.Item label="Falun Gong" value="Falun Gong" />
                    <Picker.Item label="Judaism" value="Judaism" />
                    <Picker.Item label="Confucianism" value="Confucianism" />
                    <Picker.Item label="Other..." value="Other" />
                    <Picker.Item label="Prefer Not to Say" value="Prefer Not to Say" />
                  </Picker>
                  </View>
              <View style={{height: 0}}/>
              <View style={styles.fieldInputArea} style={{marginTop: 100}}>
                <Text style={styles.fieldHeading}>
                  Please enter information about your religious beliefs.
                </Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("religiousInfo")}
                  onBlur={handleBlur("religiousInfo")}
                  value={values.religiousInfo}
                />
              </View>
              <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          livingWill: values.livingWill,
                          advanceDirective: values.advanceDirective,
                          organDonor: values.organDonor,
                          dnr: values.dnr,
                          polst: values.polst,
                          religion: values.religion,
                          religiousInfo: values.religiousInfo,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default an_LivingWill;
