import React, { useState, useRef, useEffect } from "react";
import {
  Platform,
  View,
  Text,
  TextInput,
  Pressable,
  Button,
  Image,
} from "react-native";
import { CheckBox } from "react-native-elements";
import firebase from "firebase";
import { FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";
import { firebaseConfig } from "../../config/firebase";
import { useDispatch } from "react-redux";
import { setUserWithPhone } from "../../src/features/user/userSlice";
import styles from "../../components/Stylesheet";
// import styles from "../../components/Stylesheet";
import AppButton from "../../components/AppButton";
import { SafeAreaView } from "react-native-safe-area-context";
import { Formik } from "formik";
import * as Yup from 'yup'

const SetUserWithPhoneComponent = ({ navigation }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [verificationId, setVerificationId] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const recaptchaVerifierRef = useRef(null);
  const [message, showMessage] = useState();
  const [recaptchaStatus, setRecaptchaStatus] = useState("unverified");
  const [recaptchaMessage, setRecaptchaMessage] = useState(
    "Please complete the verification step below"
  );
  const [hideVerification, setHideVerification] = useState(true);

  
  firebase.auth().useDeviceLanguage();

  useEffect(() => {
    if (Platform.OS === "web") {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          callback: function (response) {
            setRecaptchaStatus("verified");
            setRecaptchaMessage("Thank you for verifying you are not a robot!");
          },
          "expired-callback": function () {
            setRecaptchaStatus("expired");
            setRecaptchaMessage(
              "Your verification has expired. Please verify again"
            );
          },
        }
      );
      window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
    }
  }, []);

  function handleBackButtonPress() {
    navigation.navigate("SigninWithEmail");
  }

  if (Platform.OS === "ios" || Platform.OS === "android") {
    return (
      <>
        <FirebaseRecaptchaVerifierModal
          ref={recaptchaVerifierRef}
          firebaseConfig={firebaseConfig} />
        <View style={styles.container}>
        <Image
          style={styles.largeLogo}
          source={require("../../assets/largeLogo.png")}
        />
        
        <Formik
          initialValues={{
            areaCode: null,
            exchange: null,
            extension: null,
          }}
          validationSchema={Yup.object().shape({
            areaCode: Yup
              .string()
              .min(3, ({min}) => "Area Code must be three digits")
              .max(3, ({max}) => "Area Code must be three digits"),
            exchange: Yup
              .string()
              .min(3, ({min}) => "Exchange must be three digits")
              .max(3, ({max}) => "Exchange must be three digits"),
            extension: Yup
              .string()
              .min(4, ({min}) => "Subscriber number must be four digits")
              .max(4, ({max}) => "Subscriber number must be four digits"),
          })}
        >
          {({
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            errors,
            touched,
            isValid,
          }) => (
            <View style={styles.container}>
                <Text>Please enter a valid U.S. mobile phone number:</Text>
                <View style={styles.phoneNumberEntry}>
                  <TextInput style={styles.phoneDigitsOnMobile}
                    placeholder="555"
                    keyboardType="phone-pad"
                    onChangeText={handleChange("areaCode")}
                    onBlur={handleBlur("areaCode")}
                    value={values.areaCode}
                  />
                  <TextInput style={styles.phoneDigitsOnMobile}
                    placeholder="555"
                    keyboardType="phone-pad"
                    onChangeText={handleChange("exchange")}
                    onBlur={handleBlur("exchange")}
                    value={values.exchange}
                  />
                  <TextInput style={styles.phoneDigitsOnMobile}
                    placeholder="1212"
                    keyboardType="phone-pad"
                    textContentType="telephoneNumber"
                    onChangeText={handleChange("extension")}
                    onBlur={handleBlur("extension")}
                    value={values.extension}
                  />
                </View>
                <View>
                  {(errors.areaCode && touched.areaCode) &&
                    <Text style={{ fontSize: 14, color: 'red' }}>{errors.areaCode}</Text>
                  }
                  {(errors.exchange && touched.exchange) &&
                    <Text style={{ fontSize: 14, color: 'red' }}>{errors.exchange}</Text>
                  }
                  {(errors.extension && touched.extension) &&
                    <Text style={{ fontSize: 14, color: 'red' }}>{errors.extension}</Text>
                  }
                </View>
                  <CheckBox
                    title="I am 18 years of age or older"
                    checked={checked}
                    onPress={() => setChecked(!checked)}
                  />
                  <Text>{recaptchaMessage}</Text>
                  <Button
                    style={styles.basicButton}
                    title="Send Verification Code"
                    disabled={
                      checked === false
                    }
                    onPress={async () => {
                    try {
                      const phoneNumber = `+1 ${values.areaCode} ${values.exchange} ${values.extension}`
                        const phoneProvider = new firebase.auth.PhoneAuthProvider();
                        const verificationId = await phoneProvider.verifyPhoneNumber(
                        phoneNumber,
                        recaptchaVerifierRef.current
                        );
                        setVerificationId(verificationId);
                        showMessage({
                        text: "Verification code has been sent to your phone.",
                        });
                    } catch (err) {
                        showMessage({ text: `Error: ${err.message}`, color: "red" });
                    }
                    }}
                />
                  <View>
                    <Text style={{ marginTop: 20 }}>
                      Text message sent. Please enter verification code below.
                    </Text>
                    <TextInput
                        style={{ marginVertical: 10, fontSize: 17 }}
                        editable={!!verificationId}
                        placeholder="123456"
                        onChangeText={setVerificationCode}
                    />
                    <Button
                        title="Confirm Verification Code"
                        disabled={!verificationId}
                        onPress={async () => {
                        try {
                            const credential = firebase.auth.PhoneAuthProvider.credential(
                            verificationId,
                            verificationCode
                            );
                            const result = await firebase.auth().signInWithCredential(credential);
                            dispatch(setUserWithPhone(result))
                            showMessage({ text: "Phone authentication successful 👍" });
                        } catch (err) {
                            showMessage({ text: `Error: ${err.message}`, color: "red" });
                        }
                        }}
                    />
                    {message ? (
                      <Text
                        style={{
                          color: message.color || "blue",
                          fontSize: 17,
                          textAlign: "center",
                          margin: 20,
                        }}
                      >
                        {message.text}
                      </Text>
                    ) : undefined}
                  </View>
                  <AppButton onPress={handleBackButtonPress} title="Go Back" />
                </View>
          )}
        </Formik>
      </View>
    </>
  )
} else {
      return (
        <>
          <View style={styles.container}>
          <Image
            style={styles.largeLogo}
            source={require("../../assets/largeLogo.png")}
          />
          
          <Formik
            // innerRef={formRef}
            initialValues={{
              areaCode: null,
              exchange: null,
              extension: null,
            }}
            validationSchema={Yup.object().shape({
              areaCode: Yup
                .string()
                .min(3, ({min}) => "Area Code must be three digits")
                .max(3, ({max}) => "Area Code must be three digits"),
              exchange: Yup
                .string()
                .min(3, ({min}) => "Exchange must be three digits")
                .max(3, ({max}) => "Exchange must be three digits"),
              extension: Yup
                .string()
                .min(4, ({min}) => "Subscriber number must be four digits")
                .max(4, ({max}) => "Subscriber number must be four digits"),
            })}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              errors,
              touched,
              isValid,
            }) => (
              <View style={styles.container}>
                  <Text>Please enter a valid U.S. mobile phone number:</Text>
                  <View style={styles.phoneNumberEntry}>
                    <TextInput style={styles.phoneDigits}
                      placeholder="555"
                      keyboardType="phone-pad"
                      onChangeText={handleChange("areaCode")}
                      onBlur={handleBlur("areaCode")}
                      value={values.areaCode}
                    />
                    <TextInput style={styles.phoneDigits}
                      placeholder="555"
                      keyboardType="phone-pad"
                      onChangeText={handleChange("exchange")}
                      onBlur={handleBlur("exchange")}
                      value={values.exchange}
                    />
                    <TextInput style={styles.phoneDigits}
                      placeholder="1212"
                      keyboardType="phone-pad"
                      textContentType="telephoneNumber"
                      onChangeText={handleChange("extension")}
                      onBlur={handleBlur("extension")}
                      value={values.extension}
                    />
                  </View>
                  <View>
                    {(errors.areaCode && touched.areaCode) &&
                      <Text style={{ fontSize: 14, color: 'red' }}>{errors.areaCode}</Text>
                    }
                    {(errors.exchange && touched.exchange) &&
                      <Text style={{ fontSize: 14, color: 'red' }}>{errors.exchange}</Text>
                    }
                    {(errors.extension && touched.extension) &&
                      <Text style={{ fontSize: 14, color: 'red' }}>{errors.extension}</Text>
                    }
                  </View>
                    <CheckBox
                      title="I am 18 years of age or older"
                      checked={checked}
                      onPress={() => setChecked(!checked)}
                    />
                    <Text>{recaptchaMessage}</Text>
                    <div id="recaptcha-container"></div>
                    <Button
                      title="Send Verification Code"
                      disabled={
                        !checked ||
                        recaptchaStatus === "unverified" ||
                        recaptchaStatus === "expired"
                      }
                      style={styles.button}
                      onPress={async () => {
                        var appVerifier = window.recaptchaVerifier;
                        firebase
                          .auth()
                          .signInWithPhoneNumber(`+1 ${values.areaCode} ${values.exchange} ${values.extension}`, appVerifier)
                          .then(function (confirmationResult) {
                            setHideVerification(false);
                            window.confirmationResult = confirmationResult;
                          })
                          .catch(function (error) {
                            setHideVerification(false);
                            showMessage({
                              text: `Error: ${error.message}`,
                              color: "red",
                            });
                          });
                      }}
                    />
                    <View style={[{ opacity: hideVerification ? 0 : 100 }]}>
                      <Text style={{ marginTop: 20 }}>
                        Text message sent. Please enter verification code below.
                      </Text>
                      <TextInput
                        style={{ marginVertical: 10, fontSize: 17 }}
                        placeholder="123456"
                        onChangeText={setVerificationCode}
                      />
                      <Button
                        title="Confirm Verification Code"
                        disabled={!verificationCode}
                        style={styles.button}
                        onPress={async () => {
                          window.confirmationResult
                            .confirm(verificationCode)
                            .then(function (result) {
                              dispatch(setUserWithPhone(result));
                              // navigation.navigate("EULA");
                            })
                            .catch(function (error) {
                              showMessage({
                                text: `Error: ${error.message}`,
                                color: "red",
                              });
                            });
                        }}
                      />
                      {message ? (
                        <Text
                          style={{
                            color: message.color || "blue",
                            fontSize: 17,
                            textAlign: "center",
                            margin: 20,
                          }}
                        >
                          {message.text}
                        </Text>
                      ) : undefined}
                    </View>
                    <AppButton onPress={handleBackButtonPress} title="Go Back" />
                  </View>
            )}
          </Formik>
        </View>
      </>
    )}
};

export default SetUserWithPhoneComponent;
