import React, { useState, useEffect } from "react";
import { useStore, Provider } from "react-redux";
import watch from "redux-watch";
import SignedInStack from "./SignedInStack";
import SignedOutStack from "./SignedOutStack";

export default function AuthNavigator() {
  const [user, setUser] = useState(null);
  const store = useStore();

  function onAuthStateChanged(newVal) {
    setUser(newVal);
  }

  useEffect(() => {
    let observer = watch(store.getState, "user.uid");
    let updater = store.subscribe(
      observer((newVal, oldVal, objectPath) => {
        onAuthStateChanged(newVal);
      })
    );

    return updater;
  }, []);

  return user ? (
    <Provider store={store}>
      <SignedInStack />
    </Provider>
  ) : (
    <SignedOutStack />
  );
}
