export const firebaseConfig = {
  apiKey: "AIzaSyDX2MJSLo8qJgYtPyzHMuuEnK7ZXvq9pdw",
  authDomain: "mideo-1f931.firebaseapp.com",
  databaseURL: "https://mideo-1f931.firebaseio.com",
  projectId: "mideo-1f931",
  storageBucket: "mideo-1f931.appspot.com",
  messagingSenderId: "1028959646431",
  appId: "1:1028959646431:web:21b5229c582f43c35e2f6a",
  measurementId: "G-YYFXK6RDG3",
};
