import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import store from "../../app/store";
import "firebase/firestore";
import firebase from "firebase";

export const loadApplication = createAsyncThunk(
  "application/loadApplication",
  async (arg) => {
    const db = firebase.firestore();
    const uid = store.getState().user.uid;

    const form = {};

    const querySnap = await db
      .collection("users")
      .doc(`${uid}`)
      .collection("Form")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let key = doc.id;
          let values = doc.data();
          form[key] = values;
        });
      });

    return form;
  }
);

export const saveAndContinue = createAsyncThunk(
  "application/saveAndContinue",
  async (arg) => {
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;
    const form = arg;

    for (const [key, value] of Object.entries(arg)) {
      await db
        .collection("users")
        .doc(user.uid)
        .collection("Form")
        .doc(key)
        .set(value)
        .then(function () {})
        .catch(function (error) {console.error(error)});
    }

    return form;
  }
);

export const saveAndSignout = createAsyncThunk(
  "application/saveAndSignout",
  async (arg) => {
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;
    const form = arg;

    for (const [key, value] of Object.entries(arg)) {
      await db
        .collection("users")
        .doc(user.uid)
        .collection("Form")
        .doc(key)
        .set(value)
        .then(function () {})
        .catch(function (error) {});
    }

    return form;
  }
);

const initialState = {
  loadApplicationStatus: "idle",
  form: null,
  scrollable: true,
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateFormSection(state, action) {
      const sectionName = Object.keys(action.payload)[0];
      const containerObj = {};
      for (const [key, value] of Object.entries(action.payload[sectionName])) {
        containerObj[key] = value;
      }
      state.form[sectionName] = containerObj;
    },
    updateMedicareDescriptors(state, action) {
      if(state.form.MEDICARE_INFO && state.form.MEDICARE_INFO["medicareDescriptors"]) {
        state.form.MEDICARE_INFO["medicareDescriptors"] = action.payload
      }
    },
    updateMedicareABNChoice(state, action) {
      state.form.ABN_INFO["medicareABNChoice"] = action.payload
    },
    updateNonMedicareABNChoice(state, action) {
      state.form.ABN_INFO["nonMedicareABNChoice"] = action.payload
    },
    updateScrollable(state, action) {
      state.scrollable = action.payload
    },
    addSignature(state, action) {
      let sectionName = action.payload.sectionName
      let sigTitle = action.payload.sigTitle
      let uri = action.payload.uri

      if (state.form.sectionName && state.form.sectionName.sigTitle) {
        state.form[sectionName][sigTitle]["tempURI"] = uri
      } else if (state.form.sectionName) {
        state.form[sectionName][sigTitle] = {}
        state.form[sectionName][sigTitle]["tempURI"] = uri
      } else {
        state.form[sectionName] = {}
        state.form[sectionName][sigTitle] = {}
        state.form[sectionName][sigTitle]["tempURI"] = uri
      }
    }
  },  
  extraReducers: {
    [loadApplication.pending]: (state, action) => {
      state.loadApplicationStatus = "loading";
    },
    [loadApplication.fulfilled]: (state, action) => {
      state.loadApplicationStatus = "succeeded";
      state.form = action.payload;
    },
    [loadApplication.rejected]: (state, action) => {
      state.loadApplicationStatus = "failed";
      state.error = action.error.message;
    },
    [saveAndSignout.pending]: (state, action) => {
      state.saveApplicationToDBStatus = "loading";
    },
    [saveAndSignout.fulfilled]: (state, action) => {
      state.saveApplicationToDBStatus = "succeeded";
      state.form = action.payload;
    },
    [saveAndSignout.rejected]: (state, action) => {
      state.saveApplicationToDBStatus = "failed";
      state.error = action.error.message;
    },
    [saveAndContinue.pending]: (state, action) => {
      state.saveAndContinueStatus = "loading";
    },
    [saveAndContinue.fulfilled]: (state, action) => {
      state.saveAndContinueStatus = "succeeded";
      state.form = action.payload;
    },
    [saveAndContinue.rejected]: (state, action) => {
      state.saveAndContinueStatus = "failed";
      state.error = action.error.message;
    },
  },
});

export const { updateFormSection, updateMedicareDescriptors, updateMedicareABNChoice, updateNonMedicareABNChoice, updateScrollable, addSignature } = applicationSlice.actions;

export default applicationSlice.reducer;
