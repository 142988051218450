import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createUserWithEmail,
  resetEmailSignupStatus,
} from "../../src/features/user/userSlice";
import {
  SafeAreaView,
  ScrollView,
  View,
  Text,
  TextInput,
  Button,
  ActivityIndicator,
  Image,
} from "react-native";
import { CheckBox } from "react-native-elements";
import { Formik } from "formik";
import styles from "../../components/Stylesheet";
import AppButton from "../../components/AppButton";

function CreateUserWithEmail({ navigation }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const error = useSelector((state) => state.user.error);
  const emailSignupStatus = useSelector(
    (state) => state.user.emailSignupStatus
  );
  let conditions;
  if (checked === false) {
    conditions = "{!checked}";
  }

  function handleSigninWithEmailButtonPress() {
    navigation.navigate("SigninWithEmail");
  }

  function handleSigninWithPhoneButtonPress() {
    navigation.navigate("SigninWithPhone");
  }

  function handleBackButtonPress() {
    navigation.navigate("SigninWithEmail");
  }

  function handleTryCreateUserAgain() {
    dispatch(resetEmailSignupStatus());
    navigation.navigate("CreateUserWithEmail");
  }

  if (emailSignupStatus === "idle") {
    return (
      <SafeAreaView style={styles.container}>
        <ScrollView>
        <Image
          style={styles.largeLogo}
          source={require("../../assets/largeLogo.png")}
        />
        <Formik
          initialValues={{
            displayname: "",
            email: "",
            password: "",
            signupErrorMessage: "",
          }}
          onSubmit={(values) => {
            ("onSubmit triggered");
            dispatch(
              createUserWithEmail({
                displayname: values.displayname,
                email: values.email,
                password: values.password,
              })
            );
          }}
        >
          {({ handleChange, handleBlur, submitForm, values }) => (
            <>
              <View style={styles.fieldInputArea}>
              <Text style={styles.fieldHeading}>
                Full Name
              </Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("displayname")}
                  onBlur={handleBlur("displayname")}
                  value={values.name}
                  placeholder={"Full Name"}
                />
              </View>
              <View style={styles.fieldInputArea}>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("email")}
                  onBlur={handleBlur("email")}
                  value={values.email}
                  placeholder={"Email"}
                />
              </View>
              <View style={styles.fieldInputArea}>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("password")}
                  onBlur={handleBlur("password")}
                  value={values.password}
                  placeholder={"Password"}
                  secureTextEntry={true}
                />
              </View>
              <CheckBox
                title="I am 18 years of age or older"
                checked={checked}
                onPress={() => setChecked(!checked)}
              />
              {!checked ? (
                <Text>
                  You must be 18 years of age or older to create an account{" "}
                </Text>
              ) : null}
              <AppButton
                onPress={submitForm}
                title="Create Account"
                disabledConditions={conditions}
              />
            </>
          )}
        </Formik>
        <Text>Already have an account?</Text>
        <AppButton
          onPress={handleSigninWithEmailButtonPress}
          title="Sign In With Email Address"
        />
        <AppButton
          onPress={handleSigninWithPhoneButtonPress}
          title="Sign In With Mobile Phone"
        />
        <AppButton onPress={handleBackButtonPress} title="Go Back" />
        </ScrollView>
      </SafeAreaView>
    );
  } else if (emailSignupStatus === "loading") {
    return <ActivityIndicator />;
  } else if (emailSignupStatus === "succeeded") {
    return <Text>You will be redirected shortly.</Text>;
  } else if (emailSignupStatus === "failed") {
    return (
      <SafeAreaView style={styles.container}>
        <View>
        <Image
          style={styles.largeLogo}
          source={require("../../assets/largeLogo.png")}
        />
          <Text>There was an error creating that account.</Text>
          <Text>ERROR: {error}</Text>
          <Text>Please contact support if you continue to get this error.</Text>
          <AppButton title="Try Again" onPress={handleTryCreateUserAgain} style={styles.basicButton}/>
        </View>
      </SafeAreaView>
    );
  }
}

export default CreateUserWithEmail;
