import styles from "../../components/Stylesheet";
import React, { useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  TextInput,
  Alert,
  Modal,
  Pressable,
  TouchableHighlight,
  Platform
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import * as Yup from 'yup'
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import * as WebModal from 'modal-react-native-web'

const ab_PatientInformationPartOne = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const sectionName = "PATIENT_INFO_1";
  const nextSectionRoute = "PatientInfoPartTwo";
  const [localStateGender, setLocalStateGender] = useState(
    form[sectionName] ? form[sectionName].gender : null
  );
  const [localStateSelfReporting, setLocalStateSelfReporting] = useState(
    form[sectionName] ? form[sectionName].selfReporting : null
  );
  const [modalVisible, setModalVisible] = useState(false);


  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Patient Information</Text>
          <Text style={styles.sectionDescription}>
            Please enter the patient information below.
          </Text>
        </View>
        <Formik
          initialValues={{
            firstName: form[sectionName] ? form[sectionName].firstName : "",
            middleInitial: form[sectionName] ? form[sectionName].middleInitial : "",
            lastName: form[sectionName] ? form[sectionName].lastName : "",
            DOB: form[sectionName] ? form[sectionName].DOB : "",
            gender: form[sectionName] ? form[sectionName].gender : null,
            witnesses: form[sectionName] ? form[sectionName].witnesses : "",
            selfReporting: form[sectionName]
              ? form[sectionName].selfReporting
              : false,
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  firstName: values.firstName,
                  middleInitial: values.middleInitial,
                  lastName: values.lastName,
                  DOB: values.DOB,
                  gender: values.gender,
                  witnesses: values.witnesses,
                  selfReporting: values.selfReporting,
                },
              })
            )
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup
              .string()
              .required("First name is required")
              .min(2, ({min}) => `First name should be at least ${min} characters`)
              .max(10, ({max}) => `First name should be shorter than ${max} characters`),
            middleInitial: Yup
            .string()
              .required("Middle name is required")
              .min(2, ({min}) => `Middle name should be at least ${min} characters`)
              .max(10, ({max}) => `Middle name should be shorter than ${max} characters`),
            lastName: Yup
            .string()
              .required("Last name is required")
              .min(2, ({min}) => `Last name should be at least ${min} characters`)
              .max(25, ({max}) => `Last name should be shorter than ${max} characters`),
            DOB: Yup
              .date()
              .required('Date of Birth is required'),
            gender: Yup
            .mixed().oneOf(['male', 'female', 'non-binary'])
            .required('Gender selection is required'),
            witnesses: Yup
            .string()
            .required("At least one witness must be present for the completion of this form"),
            selfReporting: Yup
            .mixed().oneOf(["true", "false"])
            .required('If you are not currently working with an Advanced Care Planning Educator, you should select "I am completing MIDEO for me"'),          
          })}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
            values,
            errors,
            touched,
            isValid,
          }) => (
            <>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>First Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("firstName")}
                  onBlur={handleBlur("firstName")}
                  value={values.firstName}
                  keyboardType="email-address"
                />
                {(errors.firstName && touched.firstName) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.firstName}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Middle Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("middleInitial")}
                  onBlur={handleBlur("middleInitial")}
                  value={values.middleInitial}
                />
                {(errors.middleInitial && touched.middleInitial) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.middleInitial}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Last Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("lastName")}
                  onBlur={handleBlur("lastName")}
                  value={values.lastName}
                />
                {(errors.lastName && touched.lastName) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.lastName}</Text>
                }
              </View>


              
              
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Date of Birth</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("DOB")}
                  onBlur={event => {
                    const dateObj = new Date(values.DOB)
                    let monthString = ""
                    if (dateObj.getMonth() === 0) {
                      monthString = "January"
                    } else if (dateObj.getMonth() === 1) {
                      monthString = "February"
                    } else if (dateObj.getMonth() === 2) {
                      monthString = "March"
                    } else if (dateObj.getMonth() === 3) {
                      monthString = "April"
                    } else if (dateObj.getMonth() === 4) {
                      monthString = "May"
                    } else if (dateObj.getMonth() === 5) {
                      monthString = "June"
                    } else if (dateObj.getMonth() === 6) {
                      monthString = "July"
                    } else if (dateObj.getMonth() === 7) {
                      monthString = "August"
                    } else if (dateObj.getMonth() === 8) {
                      monthString = "September"
                    } else if (dateObj.getMonth() === 9) {
                      monthString = "October"
                    } else if (dateObj.getMonth() === 10) {
                      monthString = "November"
                    } else if (dateObj.getMonth() === 11) {
                      monthString = "December"
                    }
                  
                    const formatted = `${monthString} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
                    if (formatted !== " NaN, NaN") {
                      setFieldValue('DOB', formatted)
                    } else {
                      setFieldValue('DOB', "")
                    }
                    handleBlur(event)}
                  }
                  value={values.DOB}
                />
                {(errors.DOB && touched.DOB) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.DOB}</Text>
                }
              </View>






              <View style={styles.binaryInput}>
                <Pressable
                  disabled={localStateGender === "male"}
                  style={
                    localStateGender === "female" ||
                    localStateGender === "non-binary" ||
                    localStateGender === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("gender", "male");
                    setLocalStateGender("male");
                  }}
                >
                  <Text
                    style={
                      localStateGender === "female" ||
                      localStateGender === "non-binary"||
                      localStateGender === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    Male
                  </Text>
                </Pressable>
                <Pressable
                  disabled={localStateGender === "female"}
                  style={
                    localStateGender === "male" ||
                    localStateGender === "non-binary"||
                    localStateGender === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("gender", "female");
                    setLocalStateGender("female");
                  }}
                >
                  <Text
                    style={
                      localStateGender === "male" ||
                      localStateGender === "non-binary"||
                      localStateGender === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    Female
                  </Text>
                </Pressable>
                <Pressable
                  disabled={localStateGender === "non-binary"}
                  style={
                    localStateGender === "male" ||
                    localStateGender === "female" ||
                    localStateGender === null
                      ? styles.basicButtonDisabled
                      : styles.basicButton
                  }
                  onPress={() => {
                    setFieldValue("gender", "non-binary");
                    setLocalStateGender("non-binary");
                  }}
                >
                  <Text
                    style={
                      localStateGender === "male" ||
                      localStateGender === "female" ||
                      localStateGender === null
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }
                  >
                    Non-Binary
                  </Text>
                </Pressable>
                {(errors.gender && touched.gender) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.gender}</Text>
                }
              </View>
              <View style={styles.textAreaInputArea}>
                <Text style={styles.fieldHeading}>
                  Those present at the time of completion:
                </Text>
                <TextInput
                  multiline
                  numberOfLines={6}
                  style={styles.textInput}
                  onChangeText={handleChange("witnesses")}
                  onBlur={handleBlur("witnesses")}
                  value={values.witnesses}
                />
                {(errors.witnesses && touched.witnesses) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.witnesses}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Are you completing MIDEO for you or for someone whom you are
                  appointed as a healthcare agent?
                </Text>
                
                <View style={styles.binaryInput}>
                  <>
                    <Pressable
                      disabled={localStateSelfReporting === "true"}
                      style={
                        localStateSelfReporting === "false" ||
                        localStateSelfReporting === null
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("selfReporting", "true");
                        setLocalStateSelfReporting("true");
                      }}
                    >
                      <Text
                        style={
                          localStateSelfReporting === "false" ||
                          localStateSelfReporting === null
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        I'm completing MIDEO for me
                      </Text>
                    </Pressable>
                    <Pressable
                      disabled={localStateSelfReporting === "false"}
                      style={
                        localStateSelfReporting === "true" ||
                        localStateSelfReporting === null
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("selfReporting", "false");
                        setLocalStateSelfReporting("false");
                      }}
                    >
                      <Text
                        style={
                          localStateSelfReporting === "true" ||
                          localStateSelfReporting === null
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        I'm completing MIDEO as an agent
                      </Text>
                    </Pressable>
                  </>
                </View>
                {(errors.selfReporting && touched.selfReporting) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.selfReporting}</Text>
                }
              </View>
              {Platform.OS === 'web' ?
                <View style={styles.centeredView}>
                <WebModal
                  animationType="slide"
                  transparent={false}
                  visible={modalVisible}
                  onDismiss={() => {
                  }}>
                  <View style={styles.centeredView} >
                    <View style={styles.modalView}>
                      <Text style={styles.modalText}>More Info</Text>

                      <TouchableHighlight
                        onPress={() => {
                          setModalVisible(!modalVisible);
                        }}
                        style={{ ...styles.openButton, backgroundColor: '#2196F3' }}
                      >
                        <Text style={styles.basicButtonText}>OK</Text>
                      </TouchableHighlight>
                    </View>
                  </View>
                </WebModal>
                <TouchableHighlight
                  style={styles.openButton}
                  onPress={() => {
                    setModalVisible(true);
                  }}>
                  <Text style={styles.basicButtonText} >Learn More</Text>
                </TouchableHighlight>
              </View>
              :
              <View style={styles.centeredView}>
                <Modal
                  animationType="slide"
                  transparent={true}
                  visible={modalVisible}
                  onRequestClose={() => {
                    Alert.alert('Modal has been closed.');
                  }}>
                  <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                      <Text style={styles.modalText}>More Info...</Text>

                      <TouchableHighlight
                        style={{ ...styles.openButton, backgroundColor: '#2196F3' }}
                        onPress={() => {
                          setModalVisible(!modalVisible);
                        }}>
                        <Text style={styles.basicButtonText}>OK</Text>
                      </TouchableHighlight>
                    </View>
                  </View>
                </Modal>

                <TouchableHighlight
                  style={styles.openButton}
                  onPress={() => {
                    setModalVisible(true);
                  }}>
                  <Text style={styles.basicButtonText}>Learn More</Text>
                </TouchableHighlight>
              </View>              
              }
              <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          firstName: values.firstName,
                          middleInitial: values.middleInitial,
                          lastName: values.lastName,
                          DOB: values.DOB,
                          gender: values.gender,
                          witnesses: values.witnesses,
                          selfReporting: values.selfReporting,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default ab_PatientInformationPartOne;

