import styles from "../../components/Stylesheet";
import React, { useState, useCallback } from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  Text,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useStore } from "react-redux";
import { saveAndSignout, } from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import YoutubePlayer from "react-native-youtube-iframe";

const ae_DoctorAttorneyInformation = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const nav = useNavigation();
  const sectionName = "DOCTOR_INFO";
  const nextSectionRoute = "FacilitatorInfo";
  const [playing, setPlaying] = useState(false);

  const onStateChange = useCallback((state) => {
    if (state === "ended") {
      setPlaying(false);
      Alert.alert("video has finished playing!");
    }
  }, []);

  const togglePlaying = useCallback(() => {
    setPlaying((prev) => !prev);
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Learn More About MIDEO</Text>
          <Text style={styles.sectionDescription}>
            Watch this X minute video that will tell you more about MIDEO and
            what to expect next.
          </Text>
          <YoutubePlayer
            height={300}
            play={playing}
            videoId={"dQw4w9WgXcQ"}
            onChangeState={onStateChange}
          />
          <Pressable onPress={togglePlaying} style={styles.basicButton}>
            <Text style={styles.basicButtonText}>
              {playing ? "Pause" : "Play"}
            </Text>
          </Pressable>
        </View>
        <View>
          <Pressable
            style={styles.basicButton}
            onPress={() => navigation.navigate(nextSectionRoute)}
          >
            <Text style={styles.basicButtonText}>Continue</Text>
          </Pressable>
        </View>
        <View>
          <Pressable
            style={styles.basicButton}
            onPress={() => {
              dispatch(saveAndSignout(store.getState().application.form));
              dispatch(logout());
            }}
          >
            <Text style={styles.basicButtonText}>Save and Sign Out</Text>
          </Pressable>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default ae_DoctorAttorneyInformation;
