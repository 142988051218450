import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loginUserWithEmail,
  resetEmailLoginStatus,
} from "../../src/features/user/userSlice";
import {
  SafeAreaView,
  View,
  Text,
  TextInput,
  Button,
  ActivityIndicator,
  Image,
} from "react-native";
import { Formik } from "formik";
import styles from "../../components/Stylesheet";
import { CommonActions } from "@react-navigation/native";
import AppButton from "../../components/AppButton";

const SigninWithEmailComponent = ({ navigation }) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.user.error);
  const emailLoginStatus = useSelector((state) => state.user.emailLoginStatus);

  function handleSigninWithPhoneButtonPress() {
    navigation.navigate("SigninWithPhone");
  }

  function handleSignupWithEmailButtonPress() {
    navigation.navigate("CreateUserWithEmail");
  }

  function handleSignupWithPhoneButtonPress() {
    navigation.navigate("CreateUserWithPhone");
  }

  function handleTryAgainButtonPress() {
    dispatch(resetEmailLoginStatus({}));
    navigation.dispatch(
      CommonActions.navigate({
        name: "SigninWithEmail",
      })
    );
  }

  if (emailLoginStatus === "idle") {
    return (
      <SafeAreaView style={styles.container}>
        <Image
          style={styles.largeLogo}
          source={require("../../assets/largeLogo.png")}
        />
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={(values) => {
            dispatch(
              loginUserWithEmail({
                email: values.email,
                password: values.password,
              })
            );
          }}
        >
          {({ handleChange, handleBlur, submitForm, values }) => (
            <View style={styles.fieldInputArea}>
              <TextInput
                style={styles.textInput}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                value={values.email}
                placeholder={"Email"}
              />
              <TextInput
                style={styles.textInput}
                onChangeText={handleChange("password")}
                onBlur={handleBlur("password")}
                value={values.password}
                placeholder={"Password"}
                secureTextEntry={true}
              />
              <View>
                <AppButton
                  onPress={submitForm}
                  title="Sign in with email address"
                />
              </View>
            </View>
          )}
        </Formik>
        <View style={styles.container}>
          <AppButton
            onPress={handleSignupWithEmailButtonPress}
            title="Sign up for an account with email address"
          />
          <Text>Don't have an email address?</Text>
          <AppButton
            onPress={handleSigninWithPhoneButtonPress}
            title="Sign Up or Sign In With Mobile Phone"
          />
        </View>
      </SafeAreaView>
    );
  } else if (emailLoginStatus === "loading") {
    return <ActivityIndicator />;
  } else if (emailLoginStatus === "succeeded") {
    return <Text>You will be redirected shortly.</Text>;
  } else if (emailLoginStatus === "failed") {
    return (
      <SafeAreaView style={styles.container}>
        <View>
        <Image
          style={styles.largeLogo}
          source={require("../../assets/largeLogo.png")}
        />
          <Text>There was an error loading that account.</Text>
          <Text>ERROR: {error}</Text>
          <Text>Please contact support if you continue to get this error.</Text>
          <AppButton
            title="Try Again"
            onPress={handleTryAgainButtonPress}
          ></AppButton>
        </View>
      </SafeAreaView>
    );
}
};

export default SigninWithEmailComponent;
