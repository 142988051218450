import styles from "../../components/Stylesheet";
import React, { useRef, useState } from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  Text,
  TextInput,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import * as Yup from 'yup'

const ah_FacilitatorInfo = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const nav = useNavigation();
  const sectionName = "FACILITATOR_INFO";
  const nextSectionRoute = "HealthInsuranceInfo";
  const formRef = useRef();
  const [localStateFacilitator, setLocalStateFacilitator] = useState(
    form[sectionName] ? form[sectionName].facilitator : null
  );

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Advanced Care Planning Educator (ACPE) Information</Text>
        </View>
        <Formik
          innerRef={formRef}
          initialValues={{
            facilitator: form[sectionName]
              ? form[sectionName].facilitator
              : null,
            facilitatorID: form[sectionName]
              ? form[sectionName].facilitatorID
              : "",
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  facilitator: values.facilitator,
                  facilitatorID: values.facilitatorID,
                },
              })
            );
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
          validationSchema={Yup.object().shape({
            facilitator: Yup
            .mixed()
            .oneOf([true, false], "You must make a selection"),
            facilitatorID: Yup
            .string()
            .when('facilitator', {
              is: true,
              then: Yup.string().required('Please ask your facilitator for their ID number and enter it here'),
              otherwise: Yup.string()
            }),        
          })}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
            values,
            errors,
            touched,
            isValid,
          }) => (
            <>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Are you working with an ACPE to help you with the process?
                </Text>
                <View style={styles.binaryInput}>
                  <>
                    <Pressable
                      disabled={localStateFacilitator === true}
                      style={
                        localStateFacilitator === false || localStateFacilitator === null
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("facilitator", true);
                        setLocalStateFacilitator(true);
                      }}
                    >
                      <Text
                        style={
                          localStateFacilitator === false || localStateFacilitator === null
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        Yes
                      </Text>
                    </Pressable>
                    <Pressable
                      disabled={localStateFacilitator === false}
                      style={
                        localStateFacilitator === true || localStateFacilitator === null
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("facilitator", false);
                        setLocalStateFacilitator(false);
                      }}
                    >
                      <Text
                        style={
                          localStateFacilitator === true || localStateFacilitator === null
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        No
                      </Text>
                    </Pressable>
                    {(errors.facilitator && touched.facilitator) &&
                        <Text style={{ fontSize: 14, color: 'red' }}>{errors.facilitator}</Text>
                      }
                  </>
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>Facilitator ID</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("facilitatorID")}
                    onBlur={handleBlur("facilitatorID")}
                    value={values.facilitatorID}
                  />
                    {(errors.facilitatorID) &&
                        <Text style={{ fontSize: 14, color: 'red' }}>{errors.facilitatorID}</Text>
                      }
                </View>
              </View>
              <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          facilitator: values.facilitator,
                          facilitatorID: values.facilitatorID,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default ah_FacilitatorInfo;
