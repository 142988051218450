import React from "react";
import { Image } from "react-native";
const ApplicationLogoTitle = () => {
  return (
    <Image
      style={{ width: 68, height: 80 }}
      source={require("../assets/ApplicationHeaderImage.png")}
    />
  );
};

export default ApplicationLogoTitle;
