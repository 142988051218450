import React, { useState, useEffect } from "react";
import { SafeAreaView, Pressable, Text, ActivityIndicator } from "react-native";
import { useDispatch, useSelector, useStore } from "react-redux";
import "firebase/firestore";
import { StackActions } from "@react-navigation/native";
import firebase from "firebase";
import { logout } from "../../src/features/user/userSlice";
import { CheckBox } from "react-native-elements";
import styles from "../../components/Stylesheet";
import { loadApplication } from "../../src/features/application/applicationSlice";
import { ScrollView } from "react-native-gesture-handler";

const aa_EULA = ({ navigation }) => {
  const db = firebase.firestore();
  const store = useStore();
  const uid = store.getState().user.uid;
  const form = store.getState().application.form;
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const loadApplicationStatus = useSelector(
    (state) => state.application.loadApplicationStatus
  );

  useEffect(() => {
    navigation.addListener("beforeRemove", (e) => {
      e.preventDefault();
    });
  }, [navigation]);

  useEffect(() => {
    dispatch(loadApplication());
  }, []);

  function handleLogout(event) {
    dispatch(logout());
    navigation.dispatch(StackActions.popToTop);
  }

  function handleContinue() {
    db.collection("users").doc(uid).collection("Form").doc("EULA").set({
      agreed: true,
    });
    navigation.navigate("PatientInfoPartOne");
  }

  if (uid && loadApplicationStatus === "loading") {
    return <ActivityIndicator />;
  } else if (uid && loadApplicationStatus === "succeeded") {
    if (form.EULA !== undefined && form.EULA["agreed"] === true) {
      navigation.navigate("PatientInfoPartOne");
      return <Text>Redirecting to Patient Information form.</Text>;
    } else {
      return (
        <>
          <SafeAreaView style={styles.container}>
            <ScrollView style={styles.EULA}>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et placerat quam. Morbi in leo vitae tellus ornare auctor nec et ipsum. Suspendisse in maximus felis. Proin dignissim lectus vel risus tristique elementum. Aliquam eleifend consequat mattis. Vivamus et sem hendrerit justo consectetur sodales ut vel ligula. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse tincidunt felis nec porta mattis. Donec imperdiet nulla est, ac posuere mauris tempor vitae. Proin lacinia varius ante commodo aliquam. Duis id lectus efficitur, varius sem et, fringilla arcu. Proin a dapibus purus. Pellentesque molestie sagittis neque id tincidunt.
                {"\n"}
                {"\n"}

                Proin imperdiet lorem in eros auctor rutrum. Etiam consectetur venenatis nibh, eget pulvinar orci sollicitudin at. Pellentesque hendrerit eget enim hendrerit accumsan. Etiam scelerisque est et commodo aliquam. Sed hendrerit et elit a aliquet. Vestibulum gravida lobortis velit, vel pulvinar justo convallis at. Proin metus orci, commodo nec metus et, semper posuere sem. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer ultrices efficitur nunc, eget volutpat lectus lobortis ut.
                {"\n"}
                {"\n"}

                Sed ornare felis ac accumsan suscipit. Etiam aliquet vestibulum erat placerat sodales. Phasellus tortor dolor, porttitor ac justo eu, faucibus volutpat neque. Praesent eget diam id elit facilisis faucibus. Nunc mollis massa a sollicitudin ultrices. Proin fringilla, tortor id dapibus blandit, massa ex dapibus orci, id convallis sem dui eu diam. Proin eros neque, dapibus a fringilla et, sagittis non odio. Sed arcu augue, hendrerit tempor hendrerit sit amet, efficitur id turpis.
                {"\n"}
                {"\n"}

                Nulla vestibulum purus a erat rutrum, quis scelerisque massa cursus. Suspendisse elementum ex et tortor ornare fermentum. Aliquam lacinia, metus eu tempus scelerisque, orci lorem placerat turpis, eget luctus velit ligula sit amet eros. Integer efficitur velit vel turpis imperdiet, in mattis orci volutpat. Duis iaculis dignissim nulla, id rutrum lorem vehicula ut. Nulla venenatis quam lectus, sed efficitur nibh luctus non. Vestibulum nibh dui, ornare vitae urna vitae, pulvinar maximus ex. Cras a magna purus. Nulla tristique dignissim leo. Morbi at enim scelerisque, fermentum enim a, commodo magna. Sed vulputate lacus sapien, a vehicula diam pharetra nec. Aenean placerat dapibus nulla, non sagittis velit fringilla sit amet. Quisque ullamcorper sem feugiat nulla scelerisque, pulvinar condimentum lorem pellentesque.
                {"\n"}
                {"\n"}

                Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam nec ipsum ut turpis lobortis tincidunt sit amet vel lectus. Nulla cursus sit amet ligula vel viverra. Donec et faucibus velit. Aenean auctor neque purus, ut cursus tortor imperdiet sed. Nulla sit amet ullamcorper ligula, at varius massa. In hac habitasse platea dictumst. In tempor ex nunc. Donec aliquet bibendum lacus nec rhoncus. Pellentesque semper ornare fringilla. Cras pellentesque, urna id dictum tempor, erat nulla laoreet felis, non dapibus sapien lorem vitae ex.
                {"\n"}
                {"\n"}

                Nullam sit amet dapibus elit. In id lacinia elit. Fusce tempor neque nunc, vitae euismod justo commodo a. Integer ex ante, eleifend ut elit eget, ornare iaculis felis. Nulla dapibus interdum lacus, vitae tempus massa gravida sed. Aliquam erat volutpat. Nulla facilisis sagittis quam, id euismod nisl fermentum nec. Maecenas ullamcorper arcu tellus, aliquet aliquet sapien tristique eu.
                {"\n"}
                {"\n"}

                Etiam dignissim risus magna, at fringilla erat vulputate in. Nunc consectetur nisl a felis accumsan, nec placerat nunc sagittis. Curabitur placerat volutpat rhoncus. Vivamus sed tortor ante. Duis ut dui fermentum, porttitor ex eu, interdum sem. Suspendisse id ornare ante, sed suscipit est. Aenean pretium mauris id arcu auctor, a placerat elit tincidunt. Fusce in pretium sapien. Maecenas vitae euismod sapien. Nullam volutpat nulla neque, ut lacinia sem maximus sagittis. Proin et nibh posuere ligula molestie ullamcorper volutpat eget massa. Curabitur at sagittis eros. Nulla lobortis nunc ac urna porttitor, in maximus mi porta.
                {"\n"}
                {"\n"}

                In in arcu mollis, euismod risus semper, ornare ipsum. Vestibulum orci lacus, interdum a consequat eu, rutrum congue erat. Nunc nec nunc aliquet nunc hendrerit viverra. Fusce elit lacus, ullamcorper sit amet ornare sollicitudin, varius a dui. Nulla fringilla sem sapien, eget aliquet urna maximus id. Aenean sed justo nibh. In non dui vel ipsum dictum suscipit ut sed turpis. In vitae risus convallis, faucibus diam eu, cursus augue. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed nec dignissim risus. Suspendisse vitae dictum mi, vestibulum placerat enim. Vestibulum nec lorem risus. Phasellus nec feugiat justo. Praesent ac ligula eu augue posuere consequat eget facilisis nisi. Vestibulum erat ipsum, porttitor at lacus eget, rutrum semper risus. Fusce sollicitudin sem orci.
                {"\n"}
                {"\n"}

                Phasellus eget odio est. Aenean libero neque, varius nec purus eu, laoreet pulvinar risus. Praesent id blandit eros. Fusce feugiat sodales ligula eget mollis. Integer aliquam porttitor eros in efficitur. Etiam tristique dolor at maximus mattis. Morbi fermentum et justo vel fermentum. Cras sed scelerisque arcu. Nunc tincidunt vel ante in finibus. Nunc nisi turpis, ullamcorper id lectus ut, feugiat viverra velit. Suspendisse potenti. Phasellus sit amet mattis dolor. In nec vestibulum libero, in vehicula tellus.
                {"\n"}
                {"\n"}

                Nulla vel tempus ipsum, in auctor sapien. Suspendisse tincidunt iaculis elit, in mattis nunc consequat id. Aenean quis turpis vel ligula vestibulum faucibus in non arcu. Vestibulum eros magna, maximus ut neque in, feugiat varius risus. Vivamus sed pulvinar leo. Fusce auctor nibh neque. Pellentesque mollis diam nec ante sollicitudin tincidunt. Phasellus ut sodales leo. Ut eu ex libero. Praesent finibus sem tortor, eu porttitor dolor feugiat in. Proin lacinia nulla ut ligula blandit, id dapibus augue ultrices. Nullam eget felis egestas, pulvinar felis vel, egestas justo. Aenean vel suscipit justo. Maecenas vitae nunc ultricies, vestibulum nisl nec, dignissim erat.
              </Text>
            </ScrollView>
            <CheckBox
              title="I attest that I have read the End User License Agreement and agree to be bound by the conditions set therein."
              checked={checked}
              onPress={() => setChecked(!checked)}
            />
            <Pressable
              disabled={!checked}
              onPress={(event) => {
                handleContinue(event);
              }}
              title="Continue"
              style={styles.basicButton}
            >
              <Text style={styles.basicButtonText}>Continue</Text>
            </Pressable>
            
            <Pressable
              onPress={(event) => {
                handleLogout(event);
              }}
              title="Log Out"
              style={styles.basicButton}
            >
              <Text style={styles.basicButtonText}>Log Out</Text>
            </Pressable>
          </SafeAreaView>
        </>
      );
    }
  } else {
    return (
      <Text>
        There was an error retrieving your profile. Please try again. If this
        error continues, please contact support.
      </Text>
    );
  }
};

export default aa_EULA;
