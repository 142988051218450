import styles from "../../components/Stylesheet";
import React, { useRef, useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  Pressable,
  Text,
  TextInput,
  View,
} from "react-native";
import { Formik } from "formik";
import { useDispatch, useStore, useSelector } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import * as Yup from 'yup'
import {SignatureComponent1, SignatureComponent2, SignatureComponentWeb} from '../../components/SignatureComponents'
import * as firebase from "firebase";


const ao_AOB = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const sectionName = "ASSIGNMENT_OF_BENEFITS";
  const nextSectionRoute = "ConsentForCare";
  const formRef = useRef();
  const scrollable = useSelector((state) => state.application.scrollable);
  const uid = store.getState().user.uid;
  const [AOB_Signature1URI, setAOB_Signature1URI] = useState("")
  const [AOB_Signature2URI, setAOB_Signature2URI] = useState("")
  const [yOffset, setYOffset] = useState(0)
  const sigBox = useRef()
  const [sigBoxMeasurements, setSigBoxMeasurements] = useState()
  const otherSigBox = useRef()
  const [otherSigBoxMeasurements, setOtherSigBoxMeasurements] = useState()


async function uploadSignature(uri, sigTitle) {
    const response = await fetch(uri);
    const blob = await response.blob();
    const storage = firebase.storage();
    const imagesPath = storage.ref(`user/${uid}/images`);
    const ref = firebase
        .storage()
        .ref()        
        .child(`user/${uid}/images/${sigTitle}`);

    await ref
        .put(blob)
        .then(() => {
            imagesPath
            .child(`${sigTitle}`)
            .getDownloadURL()
            .then(function (url) {
                if  (sigTitle === 'AOB_BeneficiarySignature') {
                    setAOB_Signature1URI(url);
                } else if (sigTitle === 'AOB_OtherSignature') {
                    setAOB_Signature2URI(url)
                }
            });
            })
            .then(() => {
            alert(`Signature Successfully Uploaded`)
        })
        .catch((error) => {
        alert("There was a problem uploading your image: ", error);
        });
    }

    function handleScroll(event) {
          setYOffset(event.nativeEvent.contentOffset.y)
    }

  return (
    <>
    <SafeAreaView style={styles.container}>
        <ScrollView onScroll={handleScroll} scrollEventThrottle={16} scrollEnabled={scrollable}>
            <View>
                <Text style={styles.sectionHeader}>Assignment Of Benefits (AOB)</Text>
                <View style={styles.fieldInputArea}>
                    <Text style={styles.specialInstructions}>
                        Statement to Permit Payment of Insurance/Medicare Benefits to Provider, Physicians and Patient. 
                    </Text>
                    <Text style={styles.fieldHeading}>
                        I request payment of authorized Insurance/Medicare benefits to me or on my behalf, for any service(s) furnished to me by The Institute on Healthcare Directives, including physician services. I authorize any holder of medical and other information about me to be released to my insurance company/Medicare and its agents for any information needed to determine these benefits or benefits for related services. I understand I am responsible and will pay The Institute on Healthcare Directives for any health insurance deductibles, co-insurance(s)/co-pays, or services not covered by my insurance/Medicare program as defined by the Social Security Act.
                    </Text>
                </View>
                <View style={styles.fieldInputArea}>
                    <Text style={styles.specialInstructions}>
                        Assignment of Insurance/Medicare Benefits
                    </Text>
                    <Text style={styles.fieldHeading}>
                        I hereby assign to, and authorize that payment be made directly to The Institute on Healthcare Directives for all benefits otherwise payable to me under the terms of my insurance policies (including Major Medical policies), by reason of the service(s) described in statements rendered by The Institute of Healthcare Directives. The Institute on Healthcare Directives shall refund any payments in excess of its full regular charge(s) to the person(s) entitled to receive the same benefits. I understand that I am financially responsible for all charges not covered, or unpaid by my insurance within a reasonable time.
                    </Text>
                </View>
            </View>
            <Formik
            innerRef={formRef}
            initialValues={{
                facilitator: form[sectionName]
                ? form[sectionName].facilitator
                : null,
                facilitatorID: form[sectionName]
                ? form[sectionName].facilitatorID
                : "",
            }}
            onSubmit={(values) => {
                dispatch(
                updateFormSection({
                    [sectionName]: {
                    facilitator: values.facilitator,
                    facilitatorID: values.facilitatorID,
                    },
                })
                );
                dispatch(
                saveAndContinue(store.getState().application.form)
                );
                navigation.navigate(nextSectionRoute);
            }}
            //TODO: SET VALIDATION SCHEMA!
            validationSchema={Yup.object().shape({
                // facilitator: Yup
                // .mixed()
                // .oneOf([true, false], "You must make a selection"),
                // facilitatorID: Yup
                // .string()
                // .when('facilitator', {
                //   is: true,
                //   then: Yup.string().required('Please ask your facilitator for their ID number and enter it here'),
                //   otherwise: Yup.string()
                // }),        
            })}
            >
            {({
                handleChange,
                handleBlur,
                submitForm,
                setFieldValue,
                values,
                errors,
                touched,
                isValid,
            }) => (
                <>
                <View style={styles.fieldInputArea}>             
                    <Text style={styles.fieldHeading}>
                        Signature of Beneficiary
                    </Text>
                    <View 
                        ref={sigBox}
                        onLayout={({nativeEvent}) => {
                            setSigBoxMeasurements(nativeEvent.layout)
                          }}
                        style={styles.signatureBox}>
                        {Platform.OS !== "web" ? 
                            <SignatureComponent1 yOffset={yOffset} sectionName={sectionName} sigTitle1={"AOB_Beneficiary"}/> :
                            <SignatureComponentWeb />                        
                        }
                    </View>
                    <View>
                    <View style={{height: 20}}/>
                    <Pressable
                        style={styles.basicButton}
                        onPress={() => {
                            let sigTitle =  store.getState().application.form.ASSIGNMENT_OF_BENEFITS.AOB_Beneficiary
                            let tempURI = sigTitle.tempURI
                            uploadSignature(tempURI, "AOB_BeneficiarySignature")
                        }}
                    >
                        <Text style={styles.basicButtonText}>Upload Signature</Text>    
                    </Pressable>
                    </View>
                    <View style={{height: 30}}/>
                    <Text style={styles.fieldHeading}>
                        Other Signature
                    </Text>
                    <View 
                        ref={otherSigBox}
                        onLayout={({nativeEvent}) => {
                            setOtherSigBoxMeasurements(nativeEvent.layout)
                        }}
                        style={styles.signatureBox}>
                        {Platform.OS !== 'web' ? 
                            <SignatureComponent2 yOffset={yOffset} sectionName={sectionName} sigTitle2={"AOB_OtherSignature"}/> :
                            <SignatureComponentWeb />
                        }                            
                    </View>
                    <View style={{height: 30}}/>
                        <Pressable
                            style={styles.basicButton}
                            onPress={() => {
                                let sigTitle =  store.getState().application.form.ASSIGNMENT_OF_BENEFITS.AOB_OtherSignature
                                let tempURI = sigTitle.tempURI
                                uploadSignature(tempURI, "AOB_OtherSignature")
                            }}
                        >
                            <Text style={styles.basicButtonText}>Upload Signature</Text>    
                        </Pressable>
                    <View style={{height: 80}}/>
                    <View style={styles.fieldInputArea}>
                        <Text style={styles.fieldHeading}>
                            Reason for Other Signature
                        </Text>
                        <TextInput
                            style={styles.textInput}
                            onChangeText={handleChange("reason")}
                            onBlur={handleBlur("reason")}
                            value={values.reason}
                        />
                    </View>
                </View>
                <View>
                    <View>
                    <Pressable
                        disabled={!isValid}
                        style={
                        !isValid
                            ? styles.basicButtonDisabled
                            : styles.basicButton
                        }
                        onPress={() => {
                        submitForm();
                        navigation.navigate(nextSectionRoute);
                        }}
                    >
                        <Text  style={
                        !isValid
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }>Continue</Text>
                    </Pressable>
                    </View>
                </View>
                <View>
                    <Pressable
                    disabled={!isValid}
                    style={
                    !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                        dispatch(
                        updateFormSection({
                            [sectionName]: {
                            facilitator: values.facilitator,
                            facilitatorID: values.facilitatorID,
                            },
                        })
                        );
                        dispatch(
                        saveAndSignout(store.getState().application.form)
                        );
                        dispatch(logout());
                    }}
                    >
                    <Text style={
                        !isValid
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText}>Save and Exit</Text>
                    </Pressable>
                </View>
                </>
            )}
            </Formik>
        </ScrollView>
    </SafeAreaView>
  </>
  );
};

export default ao_AOB;
