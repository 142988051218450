import styles from "../../components/Stylesheet";
import React, { useRef, useState } from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  Text,
  TextInput,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import { CheckBox } from 'react-native-elements';
import * as Yup from 'yup'

const al_ABNInfo = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const sectionName = "ABN_INFO";
  const nextSectionRoute = "HIPAA_Auth";
  const formRef = useRef();
  const medicarePatient = store.getState().application.form.MEDICARE_INFO.recipient
  const [medicareABNChoice, setMedicareABNChoice] = useState("OPTION 2. Do not bill Medicare. You may ask to be paid now as I am responsible for payment. I cannot appeal if Medicare is not billed.")
  const [nonMedicareABNChoice, setNonMedicareABNChoice] = useState("OPTION 2. I want the MIDEO listed above, but do not bill my insurance. I understand that payment is required in advance of my MIDEO appointment. I also understand that I cannot appeal or make an insurance reimbursement claim if my insurance is not billed.")
  const [oneIsChecked, setOneIsChecked] = useState(false)
  const [twoIsChecked, setTwoIsChecked] = useState(true)
  const [threeIsChecked, setThreeIsChecked] = useState(false)
  const [fourIsChecked, setFourIsChecked] = useState(false)
  const [fiveIsChecked, setFiveIsChecked] = useState(true)
 
    
if (medicarePatient==="true") {
     return(
        <SafeAreaView style={styles.container}>
            <KeyboardAwareScrollView>
            <Formik
                innerRef={formRef}
                initialValues={{
                  medicareABNChoice: "OPTION 2. Do not bill Medicare. You may ask to be paid now as I am responsible for payment. I cannot appeal if Medicare is not billed.",
                  additionalInfo: form[sectionName] ? form[sectionName].additionalInfo : "",
                  signature: form[sectionName] ? form[sectionName].signature : "",
                }}
                onSubmit={(values) => {
                dispatch(
                  updateFormSection({
                    [sectionName]: {
                      medicareABNChoice: values.medicareABNChoice,
                      additionalInfo: values.additionalInfo,
                      signature: values.signature,
                    },
                  })
                );
                dispatch(
                  saveAndContinue(store.getState().application.form)
                );
                navigation.navigate(nextSectionRoute);
                }}
                validationSchema={Yup.object().shape({
                  medicareABNChoice: Yup
                  .string()
                    .required("You must select one option"),              
                  additionalInfo: Yup
                  .string(),
                  signature: Yup
                  .string()
                    .required("Signature is required")
                    .min(2, ({min}) => `Signature should be at least ${min} characters`)
                    .max(40, ({max}) => `Signature should be shorter than ${max} characters`),  
                })}
            >
                {({
                handleChange,
                handleBlur,
                submitForm,
                setFieldValue,
                onSubmit,
                touched,
                isValid,
                values,
                errors,
                }) => (
                <>
                    <View style={styles.fieldInputArea}>
                    <Text style={styles.fieldHeading}>
                        Advanced Beneficiary Notice of Non-Coverage (ABN) - Medicare
                        Patients Only
                    </Text>
                    <View style={styles.fieldInputArea}>
                        <Text style={styles.specialInstructions}>
                        MIDEO ID Card{"\n"}
                        Video Advance Directive
                        </Text>
                        <Text style={styles.fieldHeading}>
                        Medicare may not pay for everything. To submit your payment,
                        please enter your information and click on the "Submit
                        Payment” button below. The total cost for the process and
                        the physical MIDEO ID Card is $399.00.
                        {"\n"}
                        {"\n"}
                        {"\n"}
                        {"\n"}
                        If you choose Option 1 or 2, we may help you to use any
                        other insurance that you might have, but Medicare cannot
                        require us to do so.
                        </Text>
                        <View style={styles.checkboxGroup}>
                          <CheckBox style={styles.checkbox}
                            title="OPTION 1. I will be asked to pay now, but I also want my insurance billed for an official decision on payment. I understand if my insurance doesn’t pay, I am responsible for payment, but I can appeal to my insurance carrier. If my insurance does pay, you will refund any payments I made to you, less co-pays or deductibles."
                            checked={oneIsChecked}
                            onPress={() => {
                              let title="OPTION 1. I will be asked to pay now, but I also want my insurance billed for an official decision on payment. I understand if my insurance doesn’t pay, I am responsible for payment, but I can appeal to my insurance carrier. If my insurance does pay, you will refund any payments I made to you, less co-pays or deductibles."
                              if (oneIsChecked === false) {
                                setFieldValue('medicareABNChoice', title)
                                setOneIsChecked(true)
                                if (twoIsChecked === true) {
                                  setTwoIsChecked(false)
                                }
                                if (threeIsChecked === true) {
                                  setThreeIsChecked(false)
                                }
                                }
                              if (oneIsChecked === true) {
                                setMedicareABNChoice(undefined)
                                setFieldValue('medicareABNChoice', undefined)
                                setOneIsChecked(false)
                              }
                              }
                            }                            
                          />
                          <CheckBox style={styles.checkbox}
                            title="OPTION 2. Do not bill Medicare. You may ask to be paid now as I am responsible for payment. I cannot appeal if Medicare is not billed."
                            checked={twoIsChecked}
                            onPress={() => {
                              let title="OPTION 2. Do not bill Medicare. You may ask to be paid now as I am responsible for payment. I cannot appeal if Medicare is not billed."
                              
                              if (twoIsChecked === false) {
                                setFieldValue('medicareABNChoice', title)
                                setTwoIsChecked(true)
                                if (oneIsChecked === true) {
                                  setOneIsChecked(false)
                                }
                                if (threeIsChecked === true) {
                                  setThreeIsChecked(false)
                                }
                              }
                                if (twoIsChecked === true) {
                                  setMedicareABNChoice(undefined)
                                  setFieldValue('medicareABNChoice', undefined)
                                  setTwoIsChecked(false)
                              }
                            }
                          }                            
                          />
                          <CheckBox style={styles.checkbox}
                            title="OPTION 3. I don’t want a MIDEO Card. I understand with this choice I am not responsible for payment, and I cannot appeal to see if Medicare would pay."
                            checked={threeIsChecked}
                            onPress={() => {
                              let title="OPTION 3. I don’t want a MIDEO Card. I understand with this choice I am not responsible for payment, and I cannot appeal to see if Medicare would pay."
                              if (threeIsChecked === false) {
                                setFieldValue('medicareABNChoice', title)
                                setThreeIsChecked(true)
                                if (oneIsChecked === true) {
                                  setOneIsChecked(false)
                                }
                                if (twoIsChecked === true) {
                                  setTwoIsChecked(false)
                                }
                              }
                                if (threeIsChecked === true) {
                                  setMedicareABNChoice(undefined)
                                  setFieldValue('medicareABNChoice', undefined)
                                  setThreeIsChecked(false)
                              }
                            }
                          }                   
                          />
                        </View>     
                        {(errors.medicareABNChoice) &&
                          <Text style={{ fontSize: 14, color: 'red' }}>{errors.medicareABNChoice}</Text>
                        }
                    </View>
                    <View style={styles.fieldInputArea}>
                        <Text style={styles.fieldHeading}>
                          Additional Info
                        </Text>
                        <TextInput
                        style={styles.textInput}
                        onChangeText={handleChange("additionalInfo")}
                        onBlur={handleBlur("additionalInfo")}
                        value={values.additionalInfo}
                        />
                    </View>
                    <View style={styles.fieldInputArea}>
                        <Text style={styles.fieldHeading}>
                        Please type your full legal name to serve as your signature{" "}
                        </Text>
                        <TextInput
                        style={styles.textInput}
                        onChangeText={handleChange("signature")}
                        onBlur={handleBlur("signature")}
                        value={values.signature}
                        />
                        {(errors.signature && touched.signature) &&
                          <Text style={{ fontSize: 14, color: 'red' }}>{errors.signature}</Text>
                        }
                    </View>
                    </View>
                    <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          medicareABNChoice: values.medicareABNChoice,
                          additionalInfo: values.additionalInfo,
                          signature: values.signature,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
            </Formik>
            </KeyboardAwareScrollView>
        </SafeAreaView>
        );
}
else {
  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>
            Advanced Beneficiary Notice of Non-Coverage (ABN) - Non-Medicare
            Patients Only
          </Text>
          <Text style={styles.sectionDescription}>
            NOTE: If your insurance does not pay for MIDEO below, you may have
            to pay.  
            {"\n"}
            {"\n"}
            Your Health Insurance does not pay for everything, even some care
            that you or your health care provider have good reason to believe
            you need. We expect your insurance may not pay for MIDEO. The reason
            your insurance may not pay for MIDEO is that it may be considered
            experimental. The cost of your visit and evaluation is $399.00.
          </Text>
        </View>
        <Formik
          innerRef={formRef}
          initialValues={{            
            nonMedicareABNChoice: "OPTION 2. I want the MIDEO listed above, but do not bill my insurance. I understand that payment is required in advance of my MIDEO appointment. I also understand that I cannot appeal or make an insurance reimbursement claim if my insurance is not billed.",
            signature: form[sectionName] ? form[sectionName].signature : "",
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  nonMedicareABNChoice: values.nonMedicareABNChoice,
                  signature: values.signature,
                },
              })
            );
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
          validationSchema={Yup.object().shape({
            nonMedicareABNChoice: Yup
            .string()
              .required("You must select one option"),  
            signature: Yup
            .string()
              .required("Signature is required")
              .min(2, ({min}) => `Signature should be at least ${min} characters`)
              .max(40, ({max}) => `Signature should be shorter than ${max} characters`),  
          })}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
            onSubmit,
            touched,
            isValid,
            values,
            errors,
          }) => (
            <>
            <View style={styles.checkboxGroup}>
              <CheckBox
                title="OPTION 1. I will be asked to pay now, but I also want my insurance billed for an official decision on payment. I understand if my insurance doesn’t pay, I am responsible for payment, but I can appeal to my insurance carrier. If my insurance does pay, you will refund any payments I made to you, less co-pays or deductibles."
                checked={fourIsChecked}
                onPress={() => {
                  let title="OPTION 1. I will be asked to pay now, but I also want my insurance billed for an official decision on payment. I understand if my insurance doesn’t pay, I am responsible for payment, but I can appeal to my insurance carrier. If my insurance does pay, you will refund any payments I made to you, less co-pays or deductibles."
                  
                  if (fourIsChecked === false) {
                    setFieldValue('nonMedicareABNChoice', title)
                    setFourIsChecked(true)
                    if (fiveIsChecked === true) {
                      setFiveIsChecked(false)
                    }
                  }
                    if (fourIsChecked === true) {
                      setNonMedicareABNChoice(undefined)
                      setFieldValue('nonMedicareABNChoice', undefined)
                      setFourIsChecked(false)
                  }}}
                />
                <CheckBox
                  title="OPTION 2. I want the MIDEO listed above, but do not bill my insurance. I understand that payment is required in advance of my MIDEO appointment. I also understand that I cannot appeal or make an insurance reimbursement claim if my insurance is not billed."
                  checked={fiveIsChecked}
                  onPress={() => {
                    let title="OPTION 2. I want the MIDEO listed above, but do not bill my insurance. I understand that payment is required in advance of my MIDEO appointment. I also understand that I cannot appeal or make an insurance reimbursement claim if my insurance is not billed."
                    if (fiveIsChecked === false) {
                      setFieldValue('nonMedicareABNChoice', title)
                      setFiveIsChecked(true)
                      if (fourIsChecked === true) {
                        setFourIsChecked(false)
                      }
                    }
                      if (fiveIsChecked === true) {
                        setNonMedicareABNChoice(undefined)
                        setFieldValue('nonMedicareABNChoice', undefined)
                        setFiveIsChecked(false)
                    }
                  }
                }                   
                />
              </View>
              {(errors.nonMedicareABNChoice) &&
                <Text style={{ fontSize: 14, color: 'red' }}>{errors.nonMedicareABNChoice}</Text>
              }

              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Please type your full legal name to serve as your signature{" "}
                </Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("signature")}
                  onBlur={handleBlur("signature")}
                  value={values.signature}
                />
              </View>
              <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          medicareABNChoice: values.medicareABNChoice,
                          additionalInfo: values.additionalInfo,
                          signature: values.signature,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )}
};

export default al_ABNInfo;
