import React, { useRef, useState, useEffect } from 'react';
import { PanResponder, View, StyleSheet, Text, Pressable } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Svg, { Polyline } from 'react-native-svg';
import { useDispatch } from "react-redux";
import { addSignature, updateScrollable } from '../src/features/application/applicationSlice';
import styles from './Stylesheet';
export {SignatureComponent1, SignatureComponent2, SignatureComponentWeb}

const initialPath = [
  { x: 0, y: 0 },
];

const initialPathObj = {"initial":
  [{ x: 0, y: 0 }],
}

const SignatureComponent1 = (props) => {
  const [path1, setPath1] = useState(initialPath);
  const [signaturePathObj1, setSignaturePathObj1] = useState(initialPathObj)
  const dispatch = useDispatch();
  const viewShot = require('react-native-view-shot');
  const captureRef1 = viewShot.captureRef
  // const windowHeight = Dimensions.get('window').height;
  const [initialPosition1, setInitialPosition1] = useState(0)


  useEffect(() => {
    (async() => {
      const result = await captureRef1(signaturePic1, {
        format: "png",
        result: 'tmpfile',
        height: 150,
        width: 600,
        quality: 1,
      })
      if (result){ 
      dispatch(addSignature({
        sectionName: props.sectionName,
        sigTitle: props.sigTitle1,
        uri: result
      }))}
    })()
  },[signaturePathObj1])


  const StrokePath1 = ({ path }) => {
    const points = path.map(p => `${p.x},${p.y}`).join(' ');
    return (
      <Svg>
        <Polyline
          points={points}
          fill="none"
          stroke={"black"}
          strokeWidth="1"
        />
      </Svg>    
    );
  }
  
  const GestureRecorder1= () => {
    const pathRef1 = useRef();     
    const panResponder1 = useRef(
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderGrant: () => {
          pathRef1.current = []
          dispatch(updateScrollable(false))
        },
        onPanResponderMove: (event, gestureState) => {
          pathRef1.current.push({
            x: Math.round((gestureState.x0 + gestureState.dx)),
            y: Math.round(gestureState.moveY - (initialPosition1 - props.yOffset)),
          })          
          // setPath1([...pathRef1.current]);  BREAKS THE FLOW OF DATA ON MOBILE
        },
        onPanResponderRelease: () => {
          setPath1([...pathRef1.current])    
          setSignaturePathObj1({...signaturePathObj1, [Math.random()]:pathRef1.current})
          dispatch(updateScrollable(true))
        }
      })
    ).current
      return (
        <View
          style={StyleSheet.absoluteFill}
          {...panResponder1.panHandlers}
        />
      );
    }

    return (
      <>
      <View 
        ref={ref => {signaturePic1 = ref}}
        onLayout={e => {
          signaturePic1.measureInWindow((x, y) => {
            setInitialPosition1(y);
          });
        }}
        >
        <Svg>
          {Object.entries(signaturePathObj1).map(([key, value]) => {
            const pointsToDraw = signaturePathObj1[key].map(p => `${p.x},${p.y}`).join(' ');
            return (
            <Polyline
              key={key}
              points={pointsToDraw}
              fill="none"
              stroke={"black"}
              strokeWidth="1"
            />)
          })}
          <StrokePath1 path={path1} />
        </Svg>      
        <GestureRecorder1 />
      </View>
        <Pressable
            style={styles.basicButton}
            onPress={() => {
              setSignaturePathObj1(initialPathObj)
              setPath1(initialPath)
            }}
        >
            <Text style={styles.basicButtonText}>Clear Signature</Text>    
        </Pressable>
      </>
    );
  }

const SignatureComponent2 = (props) => {
  const [path2, setPath2] = useState(initialPath);
  const [signaturePathObj2, setSignaturePathObj2] = useState(initialPathObj)
  const dispatch = useDispatch();
  const viewShot = require('react-native-view-shot');
  const captureRef2 = viewShot.captureRef
  // const windowHeight = Dimensions.get('window').height;
  const [initialPosition2, setInitialPosition2] = useState(0)


  useEffect(() => {
    (async() => {
      const result = await captureRef2(signaturePic2, {
        format: "png",
        result: 'tmpfile',
        height: 150,
        width: 600,
        quality: 1,
      })
      if (result){ 
      dispatch(addSignature({
        sectionName: props.sectionName,
        sigTitle: props.sigTitle2,
        uri: result
      }))}
    })()
  },[signaturePathObj2])


  const StrokePath2 = ({ path }) => {
    const points = path.map(p => `${p.x},${p.y}`).join(' ');
    return (
      <Svg>
        <Polyline
          points={points}
          fill="none"
          stroke={"black"}
          strokeWidth="1"
        />
      </Svg>    
    );
  }
  
  const GestureRecorder2 = () => {
    const pathRef2 = useRef();
      
    const panResponder2 = useRef(
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderGrant: () => {
          pathRef2.current = []
          dispatch(updateScrollable(false))
        },
        onPanResponderMove: (event, gestureState) => {
          pathRef2.current.push({
            x: Math.round((gestureState.x0 + gestureState.dx)),
            y: Math.round(gestureState.moveY - (initialPosition2 - props.yOffset)),
          })          
          // setPath2([...pathRef2.current]);  BREAKS THE FLOW OF DATA ON MOBILE
        },
        onPanResponderRelease: () => {
          setPath2([...pathRef2.current])    
          setSignaturePathObj2({...signaturePathObj2, [Math.random()]:pathRef2.current})
          dispatch(updateScrollable(true))
        }
      })
    ).current
      return (
        <View
          style={StyleSheet.absoluteFill}
          {...panResponder2.panHandlers}
        />
      );
    }

    return (
      <>
      <View 
        ref={ref => {signaturePic2 = ref}}
        onLayout={e => {
          signaturePic2.measureInWindow((x, y) => {
            setInitialPosition2(y);
          });
        }}
        >
        <Svg>
          {Object.entries(signaturePathObj2).map(([key, value]) => {
            const pointsToDraw = signaturePathObj2[key].map(p => `${p.x},${p.y}`).join(' ');
            return (
            <Polyline
              key={key}
              points={pointsToDraw}
              fill="none"
              stroke={"black"}
              strokeWidth="1"
            />)
          })}
          <StrokePath2 path={path2} />
        </Svg>      
        <GestureRecorder2 />
      </View>
        <Pressable
            style={styles.basicButton}
            onPress={() => {
              setSignaturePathObj2(initialPathObj)
              setPath2(initialPath)
            }}
        >
            <Text style={styles.basicButtonText}>Clear Signature</Text>    
        </Pressable>
      </>
    );
  }

const SignatureComponentWeb = (props) => {
  return(
    <SafeAreaView>
      <View>
        <Text>We're sorry, but electronic document signing is currently only available on our iOS app.</Text>
      </View>
    </SafeAreaView>
  )
  //TODO: Rewrite and restore missing info from SignatureComponentScratch.js    
}

