import styles from "../../components/Stylesheet";
import React from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  Text,
  TextInput,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import * as Yup from 'yup'

const ae_DoctorAttorneyInformation = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const sectionName = "DOCTOR_INFO";
  const nextSectionRoute = "LearnMoreVideo";
  const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/
  const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Doctor Information</Text>
          <Text style={styles.sectionDescription}>
            Please enter the information below about your doctor and attorney.
          </Text>
        </View>
        <Formik
          initialValues={{
            doctor: form[sectionName] ? form[sectionName].doctor : "",
            drPhone: form[sectionName] ? form[sectionName].drPhone : "",
            drAddress: form[sectionName] ? form[sectionName].drAddress : "",
            hospital: form[sectionName] ? form[sectionName].hospital : "",
            hospitalAddress: form[sectionName]
              ? form[sectionName].hospitalAddress
              : "",
            attorney: form[sectionName] ? form[sectionName].attorney : "",
            attorneyPhone: form[sectionName]
              ? form[sectionName].attorneyPhone
              : "",
            attorneyAddress: form[sectionName]
              ? form[sectionName].attorneyAddress
              : "",
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  doctor: values.doctor,
                  drPhone: values.drPhone,
                  drAddress: values.drAddress,
                  hospital: values.hospital,
                  hospitalAddress: values.hospitalAddress,
                  attorney: values.attorney,
                  attorneyPhone: values.attorneyPhone,
                  attorneyAddress: values.attorneyAddress,
                },
              })
            );
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
          validationSchema={Yup.object().shape({
            doctor: Yup
            .string()
            .min(5, ({min}) => `Doctor Name should be at least ${min} characters`)
            .max(25, ({max}) => `Doctor Name should be shorter than ${max} characters`),
            drPhone: Yup
              .string()
              .matches(phoneRegExp, "Phone number invalid"),
            drAddress: Yup
            .string()
              .min(7, ({min}) => `Doctor's address should be at least ${min} characters`)
              .max(100, ({max}) => `Doctor's address should be shorter than ${max} characters`),
            hospital: Yup
            .string()
            .min(5, ({min}) => `Hospital Name should be at least ${min} characters`)
            .max(60, ({max}) => `Hospital Name should be shorter than ${max} characters`),
            hospitalAddress: Yup
            .string()
              .min(10, ({min}) => `Doctor's address should be at least ${min} characters`)
              .max(100, ({max}) => `Doctor's address should be shorter than ${max} characters`),
            attorney: Yup
            .string()
            .min(5, ({min}) => `Attorney Name should be at least ${min} characters`)
            .max(60, ({max}) => `Attorney Name should be shorter than ${max} characters`),
            attorneyPhone: Yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid'),
            attorneyAddress: Yup
            .string()
              .min(7, ({min}) => `Attorney's address should be at least ${min} characters`)
              .max(100, ({max}) => `Attorney's address should be shorter than ${max} characters`),
          })}
        >
          {({ handleChange, handleBlur, submitForm, values, errors, touched, isValid }) => (
            <>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Dr. Full Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("doctor")}
                  onBlur={handleBlur("doctor")}
                  value={values.doctor}
                />
                 {(errors.doctor && touched.doctor) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.doctor}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Dr. Phone</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("drPhone")}
                  onBlur={handleBlur("drPhone")}
                  value={values.drPhone}
                />
                 {(errors.drPhone && touched.drPhone) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.drPhone}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Doctor's Office Address</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("drAddress")}
                  onBlur={handleBlur("drAddress")}
                  value={values.drAddress}
                />
                 {(errors.drAddress && touched.drAddress) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.drAddress}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Preferred Hospital Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("hospital")}
                  onBlur={handleBlur("hospital")}
                  value={values.hospital}
                />
                 {(errors.hospital && touched.hospital) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.hospital}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Preferred Hospital Address
                </Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("hospitalAddress")}
                  onBlur={handleBlur("hospitalAddress")}
                  value={values.hospitalAddress}
                />
                 {(errors.hospitalAddress && touched.hospitalAddress) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.hospitalAddress}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Attorney Name</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("attorney")}
                  onBlur={handleBlur("attorney")}
                  value={values.attorney}
                />
                 {(errors.attorney && touched.attorney) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.attorney}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Attorney Phone</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("attorneyPhone")}
                  onBlur={handleBlur("attorneyPhone")}
                  value={values.attorneyPhone}
                />
                 {(errors.attorneyPhone && touched.attorneyPhone) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.attorneyPhone}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Attorney Address </Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("attorneyAddress")}
                  onBlur={handleBlur("attorneyAddress")}
                  value={values.attorneyAddress}
                />
                 {(errors.attorneyAddress && touched.attorneyAddress) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.attorneyAddress}</Text>
                }
              </View>
              <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          doctor: values.doctor,
                          drPhone: values.drPhone,
                          drAddress: values.drAddress,
                          hospital: values.hospital,
                          hospitalAddress: values.hospitalAddress,
                          attorney: values.attorney,
                          attorneyPhone: values.attorneyPhone,
                          attorneyAddress: values.attorneyAddress,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default ae_DoctorAttorneyInformation;
