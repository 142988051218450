import styles from "../../components/Stylesheet";
import React, { useRef, useState, useEffect } from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  Text,
  TextInput,
  Image,
  Alert,
  Platform,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import * as ImagePicker from "expo-image-picker";
import * as firebase from "firebase";
import { useDispatch, useStore } from "react-redux";
import { Camera } from 'expo-camera';
import AppButton from '../../components/AppButton';
import {
  updateMedicareDescriptors,
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import { CheckBox } from 'react-native-elements';
import { ActivityIndicator } from "react-native-web";


const aj_MedicareInfo = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const uid = store.getState().user.uid;
  const sectionName = "MEDICARE_INFO";
  const nextSectionRoute = "PhotoID";
  const formRef = useRef();
  const [cameraOpenForFrontOfCard, setCameraOpenForFrontOfCard] = useState(false)
  const [cameraOpenForBackOfCard, setCameraOpenForBackOfCard] = useState(false)
  const frontCardCamera = useRef()
  const backCardCamera = useRef()
  const [cardFrontURI, setCardFrontURI] = useState(
    form[sectionName] && form[sectionName]["medicareCardFrontURI"]
    ? form[sectionName]["medicareCardFrontURI"]
    : null
    );
  const [cardBackURI, setCardBackURI] = useState(
    form[sectionName] && form[sectionName]["medicareCardBackURI"]
    ? form[sectionName]["medicareCardBackURI"]
    : null
    );
  const [hasPermission, setHasPermission] = useState(null);
  const [localStateRecipient, setLocalStateRecipient] = useState(
    form[sectionName] && form[sectionName].recipient ? form[sectionName].recipient : "null"
    );
  const [selectedDescriptors, setSelectedDescriptors] = useState(store.getState().application.form.MEDICARE_INFO && store.getState().application.form.MEDICARE_INFO.medicareDescriptors ? store.getState().application.form.MEDICARE_INFO.medicareDescriptors : [])
  const [medicareIsChecked, setMedicareIsChecked] = useState(false)
  const [advantageIsChecked, setAdvantageIsChecked] = useState(false)
  const [supplementIsChecked, setSupplementIsChecked] = useState(false)
  const [otherIsChecked, setOtherIsChecked] = useState(false)

  useEffect(() => {
    dispatch(updateMedicareDescriptors(selectedDescriptors))
    let title1="Traditional Medicare"
    let idx1 = selectedDescriptors.indexOf(title1)
    if (idx1 !== -1) {
      setMedicareIsChecked(true)
    } 

    let title2="Medicare Advantage"
      let idx2 = selectedDescriptors.indexOf(title2)
      if (idx2 !== -1) {
        setAdvantageIsChecked(true)
      }

      let title3="I Have A Medicare Supplement"
      let idx3 = selectedDescriptors.indexOf(title3)
      if (idx3 !== -1) {
        setSupplementIsChecked(true)
      }

      let title4="Other"
      let idx4 = selectedDescriptors.indexOf(title4)
      if (idx4 !== -1) {
        setOtherIsChecked(true)
      } 

    }, [])
  
  async function onPressChooseFrontImageCameraWeb(camera) {
    let result = await camera.takePictureAsync();

    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/medicareCardFront`);

      await ref
        .put(blob)
        .then(() => {
            imagesPath
              .child("medicareCardFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            })
            .then(() => {
            alert(`Image Successfully Uploaded`)
        })
        .catch((error) => {
          alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseFrontImageCameraMobile() {
    (async () => {
        const { status } = await Camera.requestPermissionsAsync();
        setHasPermission(status === 'granted');
        }
    )();

    const result = await ImagePicker.launchCameraAsync()
    
    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/medicareCardFront`);

      await ref
        .put(blob)
        .then(() => {
            imagesPath
              .child("medicareCardFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
        })
        .catch((error) => {
            Alert.alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseFrontImageStorage() {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera roll permissions to upload your photo.');
        }
      } 
    })();
    let result = await ImagePicker.launchImageLibraryAsync()
    

    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/medicareCardFront`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("medicareCardFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
          } else {
            imagesPath
              .child("medicareCardFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            alert(`Image Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your image: ", error);
          } else {
            alert("There was a problem uploading your image: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseBackImageCameraWeb(camera) {
    let result = await camera.takePictureAsync();

    async function uploadBackImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/medicareCardBack`);

      await ref
        .put(blob)
        .then(() => {
            imagesPath
              .child("medicareCardBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            })
            .then(() => {
            alert(`Image Successfully Uploaded`)
        })
        .catch((error) => {
          alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadBackImage(result.uri);
    }
  }

  async function onPressChooseBackImageCameraMobile() {
    (async () => {
        const { status } = await Camera.requestPermissionsAsync();
        setHasPermission(status === 'granted');
        }
    )();

    const result = await ImagePicker.launchCameraAsync()
    
    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/medicareCardBack`);

      await ref
        .put(blob)
        .then(() => {
            imagesPath
              .child("medicareCardBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
        })
        .catch((error) => {
            Alert.alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseBackImageStorage() {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera roll permissions to upload your photo.');
        }
      } 
    })();
    let result = await ImagePicker.launchImageLibraryAsync()
    

    async function uploadBackImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/medicareCardBack`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("medicareCardBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
          } else {
            imagesPath
              .child("medicareCardBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            alert(`Image Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your image: ", error);
          } else {
            alert("There was a problem uploading your image: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadBackImage(result.uri);
    }
  }

  if (cameraOpenForFrontOfCard) {
    if ( hasPermission === 'granted' ) {      
      return (
        <>
          <Camera 
            ratio={"16:9"}
            ref={frontCardCamera}/>
          <AppButton onPress={() => {
            onPressChooseFrontImageCameraWeb(frontCardCamera.current)
            setCameraOpenForFrontOfCard(false)
            }} 
            title="Take Photo"
          />
        </>
      )} else {
        return <ActivityIndicator />
      }
  } else if (cameraOpenForBackOfCard) {
    if ( hasPermission === 'granted' ) {      
      return (
        <>
          <Camera 
            ratio={"16:9"}
            ref={backCardCamera}/>
          <AppButton onPress={() => {
            onPressChooseBackImageCameraWeb(backCardCamera.current)
            setCameraOpenForBackOfCard(false)
            }} 
            title="Take Photo"
          />
        </>
      )} else {
        return <ActivityIndicator />
      }
    } else {
      
        return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Medicare Information</Text>
          <Text style={styles.sectionDescription}>
            Please enter your information about Medicare below.
          </Text>
        </View>
        <Formik
          innerRef={formRef}
          initialValues={{
            recipient: form[sectionName] ? form[sectionName].recipient : "null",
            medicareDescriptors: selectedDescriptors,
            planName: form[sectionName] ? form[sectionName].planName : "",
            medicareCardFrontURI: cardFrontURI,
            medicareCardBackURI: cardBackURI,
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  recipient: values.recipient,
                  medicareDescriptors: selectedDescriptors,
                  planName: values.planName,
                  medicareCardFrontURI: cardFrontURI,
                  medicareCardBackURI: cardBackURI,
                },
              })
            );
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
            onSubmit,
            values,
            errors,
            touched,
            isValid,
          }) => (
            <>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Are you a Medicare recipient?
                </Text>
                <View style={styles.binaryInput}>
                  <>
                    <Pressable
                      disabled={localStateRecipient === "true"}
                      style={
                        localStateRecipient === "false" || localStateRecipient === "null"
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("recipient", "true");
                        setLocalStateRecipient("true");
                      }}
                    >
                      <Text
                        style={
                          localStateRecipient === "false" || localStateRecipient === "null"
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        Yes
                      </Text>
                    </Pressable>
                    <Pressable
                      disabled={localStateRecipient === "false"}
                      style={
                        localStateRecipient === "true" || localStateRecipient === "null"
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("recipient", "false");
                        setLocalStateRecipient("false");
                      }}
                    >
                      <Text
                        style={
                          localStateRecipient === "true" || localStateRecipient === "null"
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        No
                      </Text>
                    </Pressable>
                  </>
                </View>
                

                <CheckBox
                  title="Traditional Medicare"
                  checked={medicareIsChecked}
                  onPress={() => {
                    let title="Traditional Medicare"
                    let idx = selectedDescriptors.indexOf(title) // check to see if the name is already stored in the array
                    if (idx === -1) {
                      setSelectedDescriptors(selectedDescriptors => [...selectedDescriptors, title]) // if it isn't stored add it to the array
                      setMedicareIsChecked(!medicareIsChecked)
                    } else {
                      setSelectedDescriptors(selectedDescriptors.filter(item => item !== title)) // if it is stored then remove it from the array
                      setMedicareIsChecked(!medicareIsChecked)
                    }
                    }
                  }
                />

                <CheckBox
                  title="Medicare Advantage"
                  checked={advantageIsChecked}
                  onPress={() => {
                    let title="Medicare Advantage"
                    let idx = selectedDescriptors.indexOf(title) // check to see if the name is already stored in the array
                    if (idx === -1) {
                      setSelectedDescriptors(selectedDescriptors => [...selectedDescriptors, title]) // if it isn't stored add it to the array
                      setAdvantageIsChecked(!advantageIsChecked)
                    } else {
                      setSelectedDescriptors(selectedDescriptors.filter(item => item !== title)) // if it is stored then remove it from the array
                      setAdvantageIsChecked(!advantageIsChecked)
                    }
                    }
                  }
                />

                <CheckBox
                  title="I have A Medicare Supplement"
                  checked={supplementIsChecked}
                  onPress={() => {
                    let title="I Have A Medicare Supplement"
                    let idx = selectedDescriptors.indexOf(title) // check to see if the name is already stored in the array
                    if (idx === -1) {
                      setSelectedDescriptors(selectedDescriptors => [...selectedDescriptors, title]) // if it isn't stored add it to the array
                      setSupplementIsChecked(!supplementIsChecked)
                    } else {
                      setSelectedDescriptors(selectedDescriptors.filter(item => item !== title)) // if it is stored then remove it from the array
                      setSupplementIsChecked(!supplementIsChecked)
                    }
                    }
                  }
                />

                <CheckBox
                  title="Other"
                  checked={otherIsChecked}
                  onPress={() => {
                    let title="Other"
                    let idx = selectedDescriptors.indexOf(title) // check to see if the name is already stored in the array
                    if (idx === -1) {
                      setSelectedDescriptors(selectedDescriptors => [...selectedDescriptors, title]) // if it isn't stored add it to the array
                      setOtherIsChecked(!otherIsChecked)
                    } else {
                      setSelectedDescriptors(selectedDescriptors.filter(item => item !== title)) // if it is stored then remove it from the array
                      setOtherIsChecked(!otherIsChecked)
                    }
                    }
                  }
                />

                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>Name of Plan(s) </Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("planName")}
                    onBlur={handleBlur("planName")}
                    value={values.planName}
                  />
                </View>
                <View style={styles.fieldInputArea}>
                  <View style={styles.imageUploadHeading}>
                    <Text style={styles.fieldHeading}>
                      Upload Front of Medicare Card
                    </Text>
                  </View>
                  <View style={styles.photoButtonRow}>
                    <Pressable onPress={(()=> {
                      if (Platform.OS === 'web') {
                        setHasPermission('granted')
                        setCameraOpenForFrontOfCard(true) 
                      } else {
                        onPressChooseFrontImageCameraMobile()
                      }})}
                      >
                      <Image
                        style={styles.uploadButton}
                        source={require("../../assets/camera.png")}
                      />
                    </Pressable>
                    <Pressable onPress={onPressChooseFrontImageStorage}>
                      <Image
                        style={styles.uploadButton}
                        source={require("../../assets/upload.png")}
                      />
                    </Pressable>
                  </View>
                  <Image
                    style={styles.cardImage}
                    source={
                      cardFrontURI === null
                        ? require("../../assets/NoFile.png")
                        : { uri: cardFrontURI }
                    }
                  />
                </View>
                <View style={styles.fieldInputArea}>
                  <View style={styles.imageUploadHeading}>
                    <Text style={styles.fieldHeading}>
                      Upload Back of Medicare Card{"     "}
                    </Text>
                  </View>
                  <View style={styles.photoButtonRow}>
                    <Pressable onPress={(()=> {
                      if (Platform.OS === 'web') {
                        setCameraOpenForBackOfCard(true) 
                      } else {
                        onPressChooseBackImageCameraMobile()
                      }})}>
                      <Image
                        style={styles.uploadButton}
                        source={require("../../assets/camera.png")}
                      />
                    </Pressable>
                    <Pressable onPress={onPressChooseBackImageStorage}>
                      <Image
                        style={styles.uploadButton}
                        source={require("../../assets/upload.png")}
                      />
                    </Pressable>
                  </View>
                  <Image
                    style={styles.cardImage}
                    source={
                      cardFrontURI === null
                        ? require("../../assets/NoFile.png")
                        : { uri: cardBackURI }
                    }
                  />
                </View>
              </View>
              <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          recipient: values.recipient,
                          medicareDescriptors: selectedDescriptors,
                          planName: values.planName,
                          medicareCardFrontURI: cardFrontURI,
                          medicareCardBackURI: cardBackURI,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};
}

export default aj_MedicareInfo;
