import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import applicationReducer from "../features/application/applicationSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    application: applicationReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      // ignoredActions: [""],
      // In development, ignore these field paths in all actions. They will automatically be ignored in production.
      // Specifically ignoring timestamp values returned in User object returned from Firebase - Firestore call as we have no control over the return value.
      ignoredActionPaths: [
        "payload.user",
        "payload.additionalUserInfo",
        "meta.arg.user",
        "meta.arg.additionalUserInfo",
      ],
      // Ignore these paths in the state
      // ignoredPaths: [""],
    },
  }),
});

export default store;
