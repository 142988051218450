import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  appButtonContainer: {
    marginTop: 15,
    elevation: 8,
    backgroundColor: "#2274A5",
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  appButtonDisabled: {
    backgroundColor: "#000",
  },
  appButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
  },
  basicButton: {
    marginTop: 10,
    elevation: 8,
    backgroundColor: "#2274A5",
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 12,
    height: "auto",
    flexGrow: 100,
    justifyContent: "center",
  },
  basicButtonDisabled: {
    marginTop: 10,
    elevation: 8,
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 12,
    flexGrow: 100,
    justifyContent: "center",
  },
  basicButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
  },
  basicButtonDisabledText: {
    fontSize: 18,
    color: "#000",
    fontWeight: "bold",
    alignSelf: "center",
  },
  binaryInput: {
    width: "50%",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "stretch",
    alignContent: "center",
  },
  camera: {
    width: "28.125%",
    height: "50%"
  },
  cameraView: {
    height: "50%",
    width: "28.125%",
  },
  cardImage: {
    height: 180,
    width: 320,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  checkbox: {
    width: "70%",
    paddingRight: 10,
  },
  checkboxGroup: {
    width: "94%",
  },
  container: {
    flex: 1,
    backgroundColor: "#E9F1F7",
    alignItems: "center",
  },
  EULA: {
    width: "80%",
    backgroundColor: "white",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 2,
    padding: 5,
    height: "70%",
  },
  fieldInputArea: {
    paddingTop: 20,
    height: "auto",
  },
  fieldHeading: {
    fontSize: 20,
  },
  imageUploadHeading: {
    flexDirection: "row",
  },
  largeLogo: {
    marginTop: 30,
    width: 172,
    height: 200,
    alignSelf: "center",
  },
  mediumLogo: {},
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  modalTextStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: '#2274A5',
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  phoneDigits: {
    marginTop: 5,
    marginRight: 3,
    paddingLeft: 5,
    backgroundColor: "white",
    fontSize: 20,
    height: 30,
    borderRadius: 15,
    borderColor: "gray",
    borderWidth: 1,
    width: "9%",
  },
  phoneDigitsOnMobile: {
    marginTop: 5,
    marginRight: 3,
    paddingLeft: 5,
    backgroundColor: "white",
    fontSize: 20,
    height: 30,
    borderRadius: 15,
    borderColor: "gray",
    borderWidth: 1,
    width: "18%",
  },
  phoneNumberEntry: {
    flexDirection: "row",
    justifyContent: "center",
    margin: 3,
  },
  photoButtonRow: {
    flexDirection: 'row',
  },
  sectionHeader: {
    fontSize: 28,
    fontWeight: "bold",
  },
  sectionDescription: {
    fontSize: 24,
    marginTop: 5,
  },
  signatureBox: {
    width: '100%',
    height: 175,
    borderRadius: 10,
    borderColor: "black",
    borderWidth: 1,
    backgroundColor: "white",
    marginBottom: 30,
  },
  signinButton: {
    marginTop: 15,
    elevation: 8,
    backgroundColor: "#2274A5",
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  signinButtonText: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
  },
  specialInstructions: {
    fontSize: 18,
    fontWeight: "bold",
  },
  specialInstructionsContainer: {
    margin: 10,
  },
  teleprompter: {
    position: 'absolute',
    fontSize: 50,
    color: 'black'
  },
  textAreaInputArea: {
    marginTop: 20,
  },
  textInput: {
    marginTop: 5,
    paddingLeft: 10,
    backgroundColor: "white",
    fontSize: 20,
    height: 60,
    borderRadius: 15,
    borderColor: "gray",
    borderWidth: 1,
  },
  uploadButton: {
    height: 50,
    width: 50,
    resizeMode: "contain",
  },
});

export default styles;
