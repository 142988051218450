import React from "react";
import firebase from "firebase";
import { firebaseConfig } from "./config/firebase.js";
import { Provider } from "react-redux";
import store from "./src/app/store";
import AuthNavigator from "./src/navigation/AuthNavigator.js";

firebase.initializeApp(firebaseConfig);

const App = ({ navigation }) => {
  return (
    <Provider store={store}>
      <AuthNavigator />
    </Provider>
  );
};

export default App;
