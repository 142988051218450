import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import styles from "../../components/Stylesheet";
import LogoTitle from "../../components/ApplicationLogoTitle";
import aa_EULA from "../../screens/application/aa_EULA";
import ab_PatientInformationPartOne from "../../screens/application/ab_PatientInformationPartOne";
import ac_PatientInformationPartTwo from "../../screens/application/ac_PatientInformationPartTwo";
import ad_HealthcareAgentInformation from "../../screens/application/ad_HealthcareAgentInformation";
import ae_DoctorAttorneyInformation from "../../screens/application/ae_DoctorAttorneyInformation";
import af_LearnMoreVideo from "../../screens/application/af_LearnMoreVideo";
import ah_FacilitatorInfo from "../../screens/application/ah_FacilitatorInfo";
import ai_HealthInsuranceInfo from "../../screens/application/ai_HealthInsuranceInfo";
import aj_MedicareInfo from "../../screens/application/aj_MedicareInfo";
import ak_PhotoIdentification from "../../screens/application/ak_PhotoIdentification";
import al_ABNInfo from '../../screens/application/al_ABN_Info';
import am_HIPAA from "../../screens/application/am_HIPAA";
import an_LivingWIll from "../../screens/application/an_LivingWill";
import ao_OtherDocuments from "../../screens/application/ao_OtherDocuments";
import ap_AssignmentOfBenefits from "../../screens/application/ap_AssignmentOfBenefits"

const Stack = createStackNavigator();

export default function SignedInStack() {
  return (
    <NavigationContainer>
      <Stack.Navigator
        style={styles.container}
        screenOptions={{
          gestureEnabled: false,            
          headerBackTitle: "Back",
          headerStyle: {
            backgroundColor: "#E9F1F7",
            height: 120,
          },
          headerTintColor: "#000",
          headerTitleStyle: {
            fontWeight: "bold",
          },
          headerTitle: (props) => <LogoTitle {...props} />,
          headerTitleAlign: "center",
          headerRight: null,
        }}
      >
        <>
          <Stack.Screen name="EULA" component={aa_EULA} />
          <Stack.Screen
            name="PatientInfoPartOne"
            component={ab_PatientInformationPartOne}
            options={{
              headerLeft: null,
            }}
          />
          <Stack.Screen
            name="PatientInfoPartTwo"
            component={ac_PatientInformationPartTwo}
          />
          <Stack.Screen
            name="HealthcareInfo"
            component={ad_HealthcareAgentInformation}
          />
          <Stack.Screen
            name="DoctorAndAttorneyInfo"
            component={ae_DoctorAttorneyInformation}
          />
          <Stack.Screen name="LearnMoreVideo" component={af_LearnMoreVideo} />
          <Stack.Screen name="FacilitatorInfo" component={ah_FacilitatorInfo} />
          <Stack.Screen
            name="HealthInsuranceInfo"
            component={ai_HealthInsuranceInfo}
          />
          <Stack.Screen name="MedicareInfo" component={aj_MedicareInfo} />
          <Stack.Screen name="PhotoID" component={ak_PhotoIdentification} />
          <Stack.Screen name="ABNinfo" component={al_ABNInfo} />
          <Stack.Screen name="HIPAA_Auth" component={am_HIPAA} />
          <Stack.Screen name="LivingWill" component={an_LivingWIll} />
          <Stack.Screen name="OtherDocuments" component={ao_OtherDocuments} />
          <Stack.Screen name="AssignmentOfBenefits" component={ap_AssignmentOfBenefits} />
        </>
      </Stack.Navigator>
    </NavigationContainer>
  );
}
