import React, { useState } from "react";
import { Pressable, Text } from "react-native";
import styles from "./Stylesheet";

const AppButton = ({ onPress, title, disabledConditions }) => {
  if (disabledConditions) {
    return (
      <Pressable
        onPress={onPress}
        style={[styles.appButtonContainer, styles.appButtonDisabled]}
        disabled={disabledConditions}
      >
        <Text style={styles.appButtonText}>{title}</Text>
      </Pressable>
    );
  } else {
    return (
      <Pressable onPress={onPress} style={[styles.appButtonContainer]}>
        <Text style={styles.appButtonText}>{title}</Text>
      </Pressable>
    );
  }
};

export default AppButton;
