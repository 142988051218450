import styles from "../../components/Stylesheet";
import React, { useEffect, useRef, useState } from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  Text,
  TextInput,
  Image,
  Alert,
  Platform,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import * as ImagePicker from "expo-image-picker";
import * as firebase from "firebase";
import { Camera } from 'expo-camera';
import AppButton from '../../components/AppButton';
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import { ActivityIndicator } from "react-native-web";



const ak_PhotoIdentification = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const uid = store.getState().user.uid;
  const sectionName = "PHOTO_ID";
  const nextSectionRoute = "ABNinfo";
  const formRef = useRef();
  const [localStateLicensedDriver, setLocalStateLicensedDriver] = useState(
    form[sectionName] && form[sectionName].licensed ? form[sectionName].licensed : null
  );
  const [cardFrontURI, setCardFrontURI] = useState(
    form[sectionName] && form[sectionName]["photoIdentificationFrontURI"]
    ? form[sectionName]["photoIdentificationFrontURI"]
    : null
    );
  const [cardBackURI, setCardBackURI] = useState(
    form[sectionName] && form[sectionName]["photoIdentificationBackURI"]
    ? form[sectionName]["photoIdentificationBackURI"]
    : null
    );
  const [hasPermission, setHasPermission] = useState(null);
  const [cameraOpenForFrontOfCard, setCameraOpenForFrontOfCard] = useState(false)
  const [cameraOpenForBackOfCard, setCameraOpenForBackOfCard] = useState(false)
  const frontCardCamera = useRef()
  const backCardCamera = useRef()
  

  async function onPressChooseFrontImageCameraWeb(camera) {
    let result = await camera.takePictureAsync();

    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
      .storage()
      .ref()
      .child(`user/${uid}/images/photoIdentificationFront`);
      
      await ref
      .put(blob)
      .then(() => {
            imagesPath
              .child("photoIdentificationFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            })
            .then(() => {
            alert(`Image Successfully Uploaded`)
        })
        .catch((error) => {
          alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseFrontImageCameraMobile() {
    (async () => {
        const { status } = await Camera.requestPermissionsAsync();
        setHasPermission(status === 'granted');
        }
    )();

    const result = await ImagePicker.launchCameraAsync()
    
    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/photoIdentificationFront`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("photoIdentificationFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
          } else {
            imagesPath
              .child("photoIdentificationFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            alert(`Image Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your image: ", error);
          } else {
            alert("There was a problem uploading your image: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseFrontImageStorage() {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera roll permissions to upload your photo.');
        }
      } 
    })();
    let result = await ImagePicker.launchImageLibraryAsync()
    

    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/photoIdentificationFront`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("photoIdentificationFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
          } else {
            imagesPath
              .child("photoIdentificationFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            alert(`Image Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your image: ", error);
          } else {
            alert("There was a problem uploading your image: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseBackImageCameraWeb(camera) {
    let result = await camera.takePictureAsync();

    async function uploadBackImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/photoIdentificationBack`);

      await ref
        .put(blob)
        .then(() => {
            imagesPath
              .child("photoIdentificationBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            })
            .then(() => {
            alert(`Image Successfully Uploaded`)
        })
        .catch((error) => {
          alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadBackImage(result.uri);
    }
  }

  async function onPressChooseBackImageCameraMobile() {
    (async () => {
        const { status } = await Camera.requestPermissionsAsync();
        setHasPermission(status === 'granted');
        }
    )();

    const result = await ImagePicker.launchCameraAsync()
    
    async function uploadBackImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/photoIdentificationBack`);

      await ref
        .put(blob)
        .then(() => {
            imagesPath
              .child("photoIdentificationBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
        })
        .catch((error) => {
            Alert.alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadBackImage(result.uri);
    }
  }

  async function onPressChooseBackImageStorage() {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera roll permissions to upload your photo.');
        }
      } 
    })();
    let result = await ImagePicker.launchImageLibraryAsync()
    

    async function uploadBackImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/photoIdentificationBack`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("photoIdentificationBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
          } else {
            imagesPath
              .child("photoIdentificationBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            alert(`Image Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your image: ", error);
          } else {
            alert("There was a problem uploading your image: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadBackImage(result.uri);
    }
  }

  if (cameraOpenForFrontOfCard) {
    if ( hasPermission === 'granted' ) {      
      return (
        <>
          <Camera 
            ratio={"16:9"}
            ref={frontCardCamera}/>
          <AppButton onPress={() => {
            onPressChooseFrontImageCameraWeb(frontCardCamera.current)
            setCameraOpenForFrontOfCard(false)
            }} 
            title="Take Photo"
          />
        </>
      )} else {
        return <ActivityIndicator />
      }
  } else if (cameraOpenForBackOfCard) {
    if ( hasPermission === 'granted' ) {      
      return (
        <>
          <Camera 
            ratio={"16:9"}
            ref={backCardCamera}/>
          <AppButton onPress={() => {
            onPressChooseBackImageCameraWeb(backCardCamera.current)
            setCameraOpenForBackOfCard(false)
            }} 
            title="Take Photo"
          />
        </>
      )} else {
        return <ActivityIndicator />
      }
    } else {
    return (
      <SafeAreaView style={styles.container}>
        <KeyboardAwareScrollView>
          <View>
            <Text style={styles.sectionHeader}>Photo ID</Text>
            <Text style={styles.sectionDescription}>
              Please enter your photo ID information below.
            </Text>
          </View>
          <Formik
            innerRef={formRef}
            initialValues={{
              licensedDriver: form[sectionName]
                ? form[sectionName].licensedDriver
                : "null",
              licenseNumber: form[sectionName]
                ? form[sectionName].licenseNumber
                : "",
              issuingState: form[sectionName]
                ? form[sectionName].issuingState
                : "",
              photoIdentificationFrontURI: cardFrontURI,
              photoIdentificationBackURI: cardBackURI,
            }}
            onSubmit={(values) => {
              dispatch(
                updateFormSection({
                  [sectionName]: {
                    licensedDriver: values.licensedDriver,
                    licenseNumber: values.licenseNumber,
                    issuingState: values.issuingState,
                    photoIdentificationFrontURI: cardFrontURI,
                    photoIdentificationBackURI: cardBackURI,
                  },
                })
              );
              dispatch(
                saveAndContinue(store.getState().application.form)
              );
              navigation.navigate(nextSectionRoute)
            }}
          >
            {({
              handleChange,
              handleBlur,
              submitForm,
              setFieldValue,
              onSubmit,
              values,
            }) => (
              <>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>
                    Do you have a Driver's license?{" "}
                  </Text>
                  <View style={styles.binaryInput}>
                    <Pressable
                      disabled={localStateLicensedDriver === "true"}
                      style={
                        localStateLicensedDriver === "false" || localStateLicensedDriver === null
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("licensedDriver", "true");
                        setLocalStateLicensedDriver("true");
                      }}
                    >
                      <Text
                        style={
                          localStateLicensedDriver === "false" || localStateLicensedDriver === null
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        Yes
                      </Text>
                    </Pressable>
                    <Pressable
                      disabled={localStateLicensedDriver === "false"}
                      style={
                        localStateLicensedDriver === "true" || localStateLicensedDriver === null
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("licensedDriver", "false");
                        setLocalStateLicensedDriver("false");
                      }}
                    >
                      <Text
                        style={
                          localStateLicensedDriver === "true" || localStateLicensedDriver === null
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        No
                      </Text>
                    </Pressable>
                  </View>
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>
                    Driver's License Number{" "}
                  </Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("licenseNumber")}
                    onBlur={handleBlur("licenseNumber")}
                    value={values.licenseNumber}
                  />
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>Issuing State</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("issuingState")}
                    onBlur={handleBlur("issuingState")}
                    value={values.issuingState}
                  />
                </View>
              <View style={styles.fieldInputArea}>
                <View style={styles.imageUploadHeading}>
                  <Text style={styles.fieldHeading}>
                    Upload Front of Photo ID
                  </Text>
                </View>
                <View style={styles.photoButtonRow}>
                  <Pressable onPress={(()=> {
                    if (Platform.OS === 'web') {
                      setHasPermission('granted')
                      setCameraOpenForFrontOfCard(true) 
                    } else {
                      onPressChooseFrontImageCameraMobile()
                    }})}
                    >
                    <Image
                      style={styles.uploadButton}
                      source={require("../../assets/camera.png")}
                    />
                  </Pressable>
                  <Pressable onPress={onPressChooseFrontImageStorage}>
                    <Image
                      style={styles.uploadButton}
                      source={require("../../assets/upload.png")}
                    />
                  </Pressable>
                </View>
                <Image
                  style={styles.cardImage}
                  source={
                    cardFrontURI === null
                      ? require("../../assets/NoFile.png")
                      : { uri: cardFrontURI }
                  }
                />
              </View>
              <View style={styles.fieldInputArea}>
                <View style={styles.imageUploadHeading}>
                  <Text style={styles.fieldHeading}>
                    Upload Back of Photo ID
                  </Text>
                </View>
                <View style={styles.photoButtonRow}>
                  <Pressable onPress={(()=> {
                    if (Platform.OS === 'web') {
                      setCameraOpenForBackOfCard(true) 
                    } else {
                      onPressChooseBackImageCameraMobile()
                    }})}>
                    <Image
                      style={styles.uploadButton}
                      source={require("../../assets/camera.png")}
                    />
                  </Pressable>
                  <Pressable onPress={onPressChooseBackImageStorage}>
                    <Image
                      style={styles.uploadButton}
                      source={require("../../assets/upload.png")}
                    />
                  </Pressable>
                </View>
                <Image
                  style={styles.cardImage}
                  source={
                    cardFrontURI === null
                      ? require("../../assets/NoFile.png")
                      : { uri: cardBackURI }
                  }
                />
              </View>
              <View>
                <View>
                  <Pressable
                    style={styles.basicButton}
                    onPress={() => {
                      submitForm()}}
                    >
              
                    <Text style={styles.basicButtonText}>Continue</Text>
                  </ Pressable>
                </View>
              </View>
              <View>
                <Pressable
                  style={styles.basicButton}
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          licensedDriver: values.licensedDriver,
                          licenseNumber: values.licenseNumber,
                          issuingState: values.issuingState,
                          photoIdentificationFrontURI: cardFrontURI,
                          photoIdentificationBackURI: cardBackURI,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
              </>
            )}
          </Formik>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    );
  };
}

export default ak_PhotoIdentification;
