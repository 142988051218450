import styles from "../../components/Stylesheet";
import React, { useRef, useState } from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  Text,
  TextInput,
  Image,
  Alert,
  Platform,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import * as ImagePicker from "expo-image-picker";
import * as firebase from "firebase";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import { Camera } from 'expo-camera';
import AppButton from '../../components/AppButton';
import * as Yup from 'yup'
import { ActivityIndicator } from "react-native-web";

const ai_HealthInsuranceInfo = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const uid = store.getState().user.uid;
  const nav = useNavigation();
  const sectionName = "HEALTH_INSURANCE_INFO";
  const nextSectionRoute = "MedicareInfo";
  const formRef = useRef();
  const [localStateInsured, setLocalStateInsured] = useState(
    form[sectionName] ? form[sectionName].insured : null
  );
  const [cardFrontURI, setCardFrontURI] = useState(
    form[sectionName] && form[sectionName]["healthInsuranceCardFrontURI"]
      ? form[sectionName]["healthInsuranceCardFrontURI"]
      : null
  );
  const [cardBackURI, setCardBackURI] = useState(
    form[sectionName] && form[sectionName]["healthInsuranceCardBackURI"]
      ? form[sectionName]["healthInsuranceCardBackURI"]
      : null
  );
  const [hasPermission, setHasPermission] = useState(null);
  const [cameraOpenForFrontOfCard, setCameraOpenForFrontOfCard] = useState(false)
  const [cameraOpenForBackOfCard, setCameraOpenForBackOfCard] = useState(false)
  const frontCardCamera = useRef()
  const backCardCamera = useRef()
  const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/


  async function onPressChooseFrontImageCameraWeb(camera) {
      let result = await camera.takePictureAsync();

      async function uploadFrontImage(uri) {
        const response = await fetch(uri);
        const blob = await response.blob();
        const storage = firebase.storage();
        const imagesPath = storage.ref(`user/${uid}/images`);
        const ref = firebase
          .storage()
          .ref()
          .child(`user/${uid}/images/healthInsuranceCardFront`);

        await ref
          .put(blob)
          .then(() => {
              imagesPath
                .child("healthInsuranceCardFront")
                .getDownloadURL()
                .then(function (url) {
                  setCardFrontURI(url);
                });
              })
              .then(() => {
              alert(`Image Successfully Uploaded`)
          })
          .catch((error) => {
            alert("There was a problem uploading your image: ", error);
          });
      }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseFrontImageCameraMobile() {
    (async () => {
        const { status } = await Camera.requestPermissionsAsync();
        setHasPermission(status === 'granted');
        }
    )();

    const result = await ImagePicker.launchCameraAsync()
    
    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/healthInsuranceCardFront`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
            .child("healthInsuranceCardFront")
            .getDownloadURL()
            .then(function (url) {
                setCardFrontURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
          } else {
            imagesPath
              .child("healthInsuranceCardFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            alert(`Image Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your image: ", error);
          } else {
            alert("There was a problem uploading your image: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseFrontImageStorage() {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera roll permissions to upload your photo.');
        }
      } 
    })();
    let result = await ImagePicker.launchImageLibraryAsync()
    

    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/healthInsuranceCardFront`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("healthInsuranceCardFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
          } else {
            imagesPath
              .child("healthInsuranceCardFront")
              .getDownloadURL()
              .then(function (url) {
                setCardFrontURI(url);
              });
            alert(`Image Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your image: ", error);
          } else {
            alert("There was a problem uploading your image: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseBackImageCameraWeb(camera) {
    let result = await camera.takePictureAsync();

    async function uploadBackImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/healthInsuranceCardBack`);

      await ref
        .put(blob)
        .then(() => {
            imagesPath
              .child("healthInsuranceCardBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            })
            .then(() => {
            alert(`Image Successfully Uploaded`)
        })
        .catch((error) => {
          alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadBackImage(result.uri);
    }
  }

  async function onPressChooseBackImageCameraMobile() {
    (async () => {
        const { status } = await Camera.requestPermissionsAsync();
        setHasPermission(status === 'granted');
        }
    )();

    const result = await ImagePicker.launchCameraAsync()
    
    async function uploadFrontImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/healthInsuranceCardBack`);

      await ref
        .put(blob)
        .then(() => {
            imagesPath
              .child("healthInsuranceCardBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
        })
        .catch((error) => {
            Alert.alert("There was a problem uploading your image: ", error);
        });
    }
    if (!result.canceled) {
      uploadFrontImage(result.uri);
    }
  }

  async function onPressChooseBackImageStorage() {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          Alert.alert('Sorry, we need camera roll permissions to upload your photo.');
        }
      } 
    })();
    let result = await ImagePicker.launchImageLibraryAsync()
    

    async function uploadBackImage(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const imagesPath = storage.ref(`user/${uid}/images`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/images/healthInsuranceCardBack`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("healthInsuranceCardBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            Alert.alert("Image Successfully Uploaded");
          } else {
            imagesPath
              .child("healthInsuranceCardBack")
              .getDownloadURL()
              .then(function (url) {
                setCardBackURI(url);
              });
            alert(`Image Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your image: ", error);
          } else {
            alert("There was a problem uploading your image: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadBackImage(result.uri);
    }
  }

  if (cameraOpenForFrontOfCard) {
    if ( hasPermission === 'granted' ) {      
      return (
        <>
          <Camera 
            ratio={"16:9"}
            ref={frontCardCamera}/>
          <AppButton onPress={() => {
            onPressChooseFrontImageCameraWeb(frontCardCamera.current)
            setCameraOpenForFrontOfCard(false)
            }} 
            title="Take Photo"
          />
        </>
      )} else {
        return <ActivityIndicator />
      }
  } else if (cameraOpenForBackOfCard) {
    if ( hasPermission === 'granted' ) {      
      return (
        <>
          <Camera 
            ratio={"16:9"}
            ref={backCardCamera}/>
          <AppButton onPress={() => {
            onPressChooseBackImageCameraWeb(backCardCamera.current)
            setCameraOpenForBackOfCard(false)
            }} 
            title="Take Photo"
          />
        </>
      )} else {
        return <ActivityIndicator />
      }
    } else {
    return (
      <>
      <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Heath Insurance Information</Text>
          <Text style={styles.sectionDescription}>
            Please enter your health insurance information below.
          </Text>
        </View>
        <Formik
          innerRef={formRef}
          initialValues={{
            insured: form[sectionName] ? form[sectionName].insured : true,
            insuranceCo: form[sectionName] ? form[sectionName].insuranceCo : "",
            secondaryInsuranceCo: form[sectionName]
              ? form[sectionName].secondaryInsuranceCo
              : "",
            spouseName: form[sectionName] ? form[sectionName].spouseName : "",
            spouseDOB: form[sectionName] ? form[sectionName].spouseDOB : "",
            street: form[sectionName] ? form[sectionName].street : "",
            city: form[sectionName] ? form[sectionName].city : "",
            state: form[sectionName] ? form[sectionName].state : "",
            zip: form[sectionName] ? form[sectionName].zip : "",
            country: form[sectionName] ? form[sectionName].country : "",
            healthInsuranceCardFrontURI: cardFrontURI,
            healthInsuranceCardBackURI: cardBackURI,
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  insured: values.insured,
                  insuranceCo: values.insuranceCo,
                  secondaryInsuranceCo: values.secondaryInsuranceCo,
                  spouseName: values.spouseName,
                  spouseDOB: values.spouseDOB,
                  street: values.street,
                  city: values.city,
                  state: values.state,
                  zip: values.zip,
                  country: values.country,
                  healthInsuranceCardFrontURI: cardFrontURI,
                  healthInsuranceCardBackURI: cardBackURI,
                },
              })
            );
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
          validationSchema={Yup.object().shape({
            insured: Yup
            .mixed()
            .oneOf([true, false], "You must make a selection"),
            insuranceCo: Yup
            .string()
            .when('insured', {
              is: true,
              then: Yup.string()
              .required('Please enter your insurance company name')
              .min(2, ({min}) => `Insurance company name should be at least ${min} characters`)
              .max(30, ({max}) => `Insurance company name should be shorter than ${max} characters`),
              otherwise: Yup.string()
            }),
            secondaryInsuranceCo: Yup
            .string()
            .min(2, ({min}) => `Secondary Insurance company name should be at least ${min} characters`)
            .max(30, ({max}) => `Secondary Insurance company name should be shorter than ${max} characters`),
            spouseName: Yup
            .string()
            .min(2, ({min}) => `Spouse's name should be at least ${min} characters`)
            .max(10, ({max}) => `Spouse's name should be shorter than ${max} characters`),
            spouseDOB: Yup
            .date(),
            street: Yup
            .string()
            .min(7, ({min}) => `Street Address should be at least ${min} characters`)
            .max(100, ({max}) => `Street Address should be shorter than ${max} characters`),
            city: Yup
            .string()
            .min(2, ({min}) => `City name should be at least ${min} characters`)
            .max(20, ({max}) => `City name should be shorter than ${max} characters`),
            state: Yup
            .string()
            .min(2, ({min}) => `Please use your state's two letter postal designation`)
            .max(2, ({max}) => `Please use your state's two letter postal designation`),
            ZIP: Yup
            .string()
            .matches(zipRegExp, 'Zip code is not valid'),
            country: Yup
            .string(),
          })}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
            onSubmit,
            values,
            errors,
            touched,
            isValid,
          }) => (
            <>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>
                  Do you currently have health insurance?
                </Text>
                <View style={styles.binaryInput}>
                  <>
                    <Pressable
                      disabled={localStateInsured === true}
                      style={
                        (localStateInsured === false || localStateInsured == null)
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("insured", true);
                        setLocalStateInsured(true);
                      }}
                    >
                      <Text
                        style={
                          (localStateInsured === false || localStateInsured == null)
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        Yes
                      </Text>
                    </Pressable>
                    <Pressable
                      disabled={localStateInsured === false}
                      style={
                        (localStateInsured === true || localStateInsured == null)
                          ? styles.basicButtonDisabled
                          : styles.basicButton
                      }
                      onPress={() => {
                        setFieldValue("insured", false);
                        setLocalStateInsured(false);
                      }}
                    >
                      <Text
                        style={
                          (localStateInsured === true || localStateInsured == null)
                            ? styles.basicButtonDisabledText
                            : styles.basicButtonText
                        }
                      >
                        No
                      </Text>
                    </Pressable>
                  </>
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>
                    Health Insurance Company Name{" "}
                  </Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("insuranceCo")}
                    onBlur={handleBlur("insuranceCo")}
                    value={values.insuranceCo}
                  />
                  {(errors.insuranceCo) &&
                        <Text style={{ fontSize: 14, color: 'red' }}>{errors.insuranceCo}</Text>
                      }
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>
                    Secondary Health Insurance Company Name{" "}
                  </Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("secondaryInsuranceCo")}
                    onBlur={handleBlur("secondaryInsuranceCo")}
                    value={values.secondaryInsuranceCo}
                  />
                  {(errors.secondaryInsuranceCo && touched.secondaryInsuranceCo) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.secondaryInsuranceCo}</Text>
                }
                </View>
                <View style={styles.specialInstructionsContainer}>
                  <Text style={styles.specialInstructions}>
                    If coverage is through your spouse for primary or secondary
                    insurance, please provide your spouse’s full name and date
                    of birth.
                  </Text>
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>Spouse's Full Name</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("spouseName")}
                    onBlur={handleBlur("spouseName")}
                    value={values.spouseName}
                  />
                     {(errors.spouseName && touched.spouseName) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.spouseName}</Text>
                }
                </View>
                <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Spouse's Date of Birth</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("spouseDOB")}
                  onBlur={event => {
                    const dateObj = new Date(values.spouseDOB)
                    let monthString = ""
                    if (dateObj.getMonth() === 0) {
                      monthString = "January"
                    } else if (dateObj.getMonth() === 1) {
                      monthString = "February"
                    } else if (dateObj.getMonth() === 2) {
                      monthString = "March"
                    } else if (dateObj.getMonth() === 3) {
                      monthString = "April"
                    } else if (dateObj.getMonth() === 4) {
                      monthString = "May"
                    } else if (dateObj.getMonth() === 5) {
                      monthString = "June"
                    } else if (dateObj.getMonth() === 6) {
                      monthString = "July"
                    } else if (dateObj.getMonth() === 7) {
                      monthString = "August"
                    } else if (dateObj.getMonth() === 8) {
                      monthString = "September"
                    } else if (dateObj.getMonth() === 9) {
                      monthString = "October"
                    } else if (dateObj.getMonth() === 10) {
                      monthString = "November"
                    } else if (dateObj.getMonth() === 11) {
                      monthString = "December"
                    }
                  
                    const formatted = `${monthString} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
                    if (formatted !== " NaN, NaN") {
                      setFieldValue('spouseDOB', formatted)
                    } else {
                      setFieldValue('spouseDOB', "")
                    }
                    handleBlur(event)}
                  }
                  value={values.spouseDOB}
                />
                {(errors.spouseDOB && touched.spouseDOB) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.spouseDOB}</Text>
                }
              </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>Street Address</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("street")}
                    onBlur={handleBlur("street")}
                    value={values.street}
                  />
                  {(errors.street && touched.street) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.street}</Text>
                }
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>City</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("city")}
                    onBlur={handleBlur("city")}
                    value={values.city}
                  />
                     {(errors.city && touched.city) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.city}</Text>
                }
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>State</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("state")}
                    onBlur={handleBlur("state")}
                    value={values.state}
                  />
                     {(errors.state && touched.state) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.state}</Text>
                }
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>ZIP</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("ZIP")}
                    onBlur={handleBlur("ZIP")}
                    value={values.ZIP}
                  />
                     {(errors.ZIP && touched.ZIP) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.ZIP}</Text>
                }
                </View>
                <View style={styles.fieldInputArea}>
                  <Text style={styles.fieldHeading}>Country</Text>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={handleChange("country")}
                    onBlur={handleBlur("country")}
                    value={values.country}
                  />
                     {(errors.country && touched.country) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.country}</Text>
                }
                </View>
                <View style={styles.fieldInputArea}>
                  <View style={styles.imageUploadHeading}>
                    <Text style={styles.fieldHeading}>
                      Upload Front of Medical Insurance Card
                    </Text>
                  </View>
                  <View style={styles.photoButtonRow}>
                    <Pressable onPress={(()=> {
                      if (Platform.OS === 'web') {
                        setHasPermission('granted')
                        setCameraOpenForFrontOfCard(true) 
                      } else {
                        onPressChooseFrontImageCameraMobile()
                      }})}
                      >
                      <Image
                        style={styles.uploadButton}
                        source={require("../../assets/camera.png")}
                      />
                    </Pressable>
                    <Pressable onPress={onPressChooseFrontImageStorage}>
                      <Image
                        style={styles.uploadButton}
                        source={require("../../assets/upload.png")}
                      />
                    </Pressable>
                  </View>
                  <Image
                    style={styles.cardImage}
                    source={
                      cardFrontURI === null
                        ? require("../../assets/NoFile.png")
                        : { uri: cardFrontURI }
                    }
                  />
                </View>
                <View style={styles.fieldInputArea}>
                  <View style={styles.imageUploadHeading}>
                    <Text style={styles.fieldHeading}>
                      Upload Back of Medical Insurance Card
                    </Text>
                  </View>
                  <View style={styles.photoButtonRow}>
                    <Pressable onPress={(()=> {
                      if (Platform.OS === 'web') {
                        setCameraOpenForBackOfCard(true) 
                      } else {
                        onPressChooseBackImageCameraMobile()
                      }})}>
                      <Image
                        style={styles.uploadButton}
                        source={require("../../assets/camera.png")}
                      />
                    </Pressable>
                    <Pressable onPress={onPressChooseBackImageStorage}>
                      <Image
                        style={styles.uploadButton}
                        source={require("../../assets/upload.png")}
                      />
                    </Pressable>
                  </View>
                  <Image
                    style={styles.cardImage}
                    source={
                      cardFrontURI === null
                        ? require("../../assets/NoFile.png")
                        : { uri: cardBackURI }
                    }
                  />
                </View>
              </View>
              
              <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          insured: values.insured,
                          insuranceCo: values.insuranceCo,
                          secondaryInsuranceCo: values.secondaryInsuranceCo,
                          spouseName: values.spouseName,
                          spouseDOB: values.spouseDOB,
                          street: values.street,
                          city: values.city,
                          state: values.state,
                          zip: values.zip,
                          country: values.country,
                          healthInsuranceCardFrontURI: cardFrontURI,
                          healthInsuranceCardBackURI: cardBackURI,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
    
      </KeyboardAwareScrollView>
    </SafeAreaView>
    </>
  );
};
}

export default ai_HealthInsuranceInfo;
