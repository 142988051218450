import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import "firebase/firestore";
import firebase from "firebase";

const initialState = {
  uid: null,
  displayName: null,
  emailLoginStatus: "idle",
  emailSignupStatus: "idle",
  error: null,
};

export const loginUserWithEmail = createAsyncThunk(
  "user/loginUser",
  async (arg) => {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(arg.email, arg.password);
    return response;
  }
);

export const createUserWithEmail = createAsyncThunk(
  "user/createUserWithEmail",
  async (arg) => {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(arg.email, arg.password);
    let firebaseAppDefined = false;

    setInterval(() => {
      if (!firebaseAppDefined) {
        if (firebase.app()) {
          const db = firebase.firestore();
          db.collection("users").doc(response.user.uid).set({
            uid: response.user.uid,
            displayName: arg.displayname,
            email: response.user.email,
            applicationStatus: "open",
          });
          firebaseAppDefined = true;
        }
      }
    }, 100);

    return response;
  }
);

export const setUserWithPhone = createAsyncThunk(
  "user/setUserWithPhone",
  async (AuthResult) => {
    const response = AuthResult;
    
    let firebaseAppDefined = false;
    
    setInterval(() => {
      if (!firebaseAppDefined) {
        if (firebase.app()) {
          const db = firebase.firestore()
          db.collection("users").doc(response.user.uid).set({
            uid: response.user.uid,
            applicationStatus: "open",
          });
          firebaseAppDefined = true;
        }
      }
    }, 100);
    
    return response
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetEmailLoginStatus(state, action) {
      state.emailLoginStatus = "idle";
    },
    resetEmailSignupStatus(state, action) {
      state.emailSignupStatus = "idle";
    },

    logout(state, action) {
      state.uid = null;
      state.emailLoginStatus = "idle";
      state.emailSignupStatus = "idle";
      state.phoneLoginStatus = "idle";
      state.phoneSignupStatus = "idle";
      state.error = null;
    },
  },
  extraReducers: {
    [loginUserWithEmail.pending]: (state, action) => {
      state.emailLoginStatus = "loading";
    },
    [loginUserWithEmail.fulfilled]: (state, action) => {
      state.emailLoginStatus = "succeeded";
      let payloadId = action.payload.user.uid;
      state.uid = payloadId;
    },
    [loginUserWithEmail.rejected]: (state, action) => {
      state.emailLoginStatus = "failed";
      state.error = action.error.message;
    },
    [createUserWithEmail.pending]: (state, action) => {
      state.emailSignupStatus = "loading";
    },
    [createUserWithEmail.fulfilled]: (state, action) => {
      state.emailSignupStatus = "succeeded";
      let payloadId = action.payload.user.uid;
      state.uid = payloadId;
    },
    [createUserWithEmail.rejected]: (state, action) => {
      state.emailSignupStatus = "failed";
      state.error = action.error.message;
    },
    [setUserWithPhone.pending]: (state, action) => {
      state.setUserWithPhoneStatus = "loading";
    },
    [setUserWithPhone.fulfilled]: (state, action) => {
      state.setUserWithPhoneStatus = "succeeded";
      // let payloadId = action.payload.user.uid;  CHANGED EARLY OCTOBER 2020
      let uidFromAction = action.meta.arg.user.uid
      state.uid = uidFromAction;
    },
    [setUserWithPhone.rejected]: (state, action) => {
      state.setUserWithPhoneStatus = "failed";
      state.error = action.error.message;
    },
  },
});

export const {
  logout,
  resetEmailLoginStatus,
  resetEmailSignupStatus,
} = userSlice.actions;

export default userSlice.reducer;

export const currentUser = (state) => state.user.uid;
