import styles from "../../components/Stylesheet";
import React, { useEffect } from "react";
import {
  SafeAreaView,
  View,
  Button,
  Text,
  TextInput,
  Pressable,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'

import { Formik } from "formik";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import * as Yup from 'yup'

const ac_PatientInformationPartTwo = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const nav = useNavigation();
  const sectionName = "PATIENT_INFO_2";
  const nextSectionRoute = "HealthcareInfo";
  const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
  const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Patient Information</Text>
          <Text style={styles.sectionDescription}>
            Please enter the patient information below.
          </Text>
        </View>
        <Formik
          initialValues={{
            streetAddress: form[sectionName]
              ? form[sectionName].streetAddress
              : "",
            city: form[sectionName] ? form[sectionName].city : "",
            state: form[sectionName] ? form[sectionName].state : "",
            ZIP: form[sectionName] ? form[sectionName].ZIP : "",
            country: form[sectionName] ? form[sectionName].country : "",
            homePhone: form[sectionName] ? form[sectionName].homePhone : "",
            cellPhone: form[sectionName] ? form[sectionName].cellPhone : "",
            email: form[sectionName] ? form[sectionName].email : "",
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  streetAddress: values.streetAddress,
                  city: values.city,
                  state: values.state,
                  ZIP: values.ZIP,
                  country: values.country,
                  homePhone: values.homePhone,
                  cellPhone: values.cellPhone,
                  email: values.email,
                },
              })
            );
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
          validationSchema={Yup.object().shape({
            streetAddress: Yup
              .string()
              .required(" Street Address is required")
              .min(2, ({min}) => ` Street Address should be at least ${min} characters`)
              .max(25, ({max}) => ` Street Address should be shorter than ${max} characters`),
            city: Yup
            .string()
              .required("City name is required")
              .min(2, ({min}) => `City name should be at least ${min} characters`)
              .max(20, ({max}) => `City name should be shorter than ${max} characters`),
            state: Yup
            .string()
              .required("Last name is required")
              .min(2, ({min}) => `Please use your state's two letter postal designation`)
              .max(2, ({max}) => `Please use your state's two letter postal designation`),
            ZIP: Yup
              .string()
              .required('Postal Code is required')
              .matches(zipRegExp, 'Zip code is not valid'),
            country: Yup
            .string()
            .required('Country selection is required'),
            homePhone: Yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid'),
            cellPhone: Yup
            .string()
            .required("Cell phone number required for emergency contact.")
            .matches(phoneRegExp, 'Phone number is not valid'),
            email: Yup
            .string()
            .required('Email required')      
            .email('Email Invalid'),
          })}
        >
          {({ handleChange, handleBlur, submitForm, values, errors, touched, isValid }) => (
            <>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Street Address</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("streetAddress")}
                  onBlur={handleBlur("streetAddress")}
                  value={values.streetAddress}
                />
                {(errors.streetAddress && touched.streetAddress) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.streetAddress}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>City</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("city")}
                  onBlur={handleBlur("city")}
                  value={values.city}
                />
                {(errors.city && touched.city) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.city}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>State</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("state")}
                  onBlur={handleBlur("state")}
                  value={values.state}
                />
                {(errors.state && touched.state) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.state}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>ZIP</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("ZIP")}
                  onBlur={handleBlur("ZIP")}
                  value={values.ZIP}
                />
                {(errors.ZIP && touched.ZIP) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.ZIP}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Country</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("country")}
                  onBlur={handleBlur("country")}
                  value={values.country}
                />
                {(errors.country && touched.country) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.country}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Home Phone (optional)</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("homePhone")}
                  onBlur={handleBlur("homePhone")}
                  value={values.homePhone}
                />
                {(errors.homePhone && touched.homePhone) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.homePhone}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Cell Phone </Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("cellPhone")}
                  onBlur={handleBlur("cellPhone")}
                  value={values.cellPhone}
                />
                {(errors.cellPhone && touched.cellPhone) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.cellPhone}</Text>
                }
              </View>
              <View style={styles.fieldInputArea}>
                <Text style={styles.fieldHeading}>Email</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={handleChange("email")}
                  onBlur={handleBlur("email")}
                  value={values.email}
                />
                {(errors.email && touched.email) &&
                  <Text style={{ fontSize: 14, color: 'red' }}>{errors.email}</Text>
                }
              </View>
              <View>
                <View>
                  <Pressable
                    disabled={!isValid}
                    style={
                      !isValid
                        ? styles.basicButtonDisabled
                        : styles.basicButton
                    }
                    onPress={() => {
                      submitForm();
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text  style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText
                    }>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                disabled={!isValid}
                style={
                  !isValid
                    ? styles.basicButtonDisabled
                    : styles.basicButton
                }
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          streetAddress: values.streetAddress,
                          city: values.city,
                          state: values.state,
                          ZIP: values.ZIP,
                          country: values.country,
                          homePhone: values.homePhone,
                          cellPhone: values.cellPhone,
                          email: values.email,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={
                      !isValid
                        ? styles.basicButtonDisabledText
                        : styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
          </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default ac_PatientInformationPartTwo;
