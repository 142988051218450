import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import SigninWithEmail from "../../screens/login/SigninWithEmail";
import CreateUserWithEmail from "../../screens/login/CreateUserWithEmail";
import SetUserWithPhone from "../../screens/login/SetUserWithPhone";

const Stack = createStackNavigator();

export default function SignedOutStack() {
  return (
    <NavigationContainer>
      <Stack.Navigator headerMode="none">
        <>
          <Stack.Screen
            name="SigninWithEmail"
            component={SigninWithEmail}
            // options={{ headerTitle: (props) => <LogoTitle {...props} /> }}
          />
          <Stack.Screen
            name="CreateUserWithEmail"
            component={CreateUserWithEmail}
          />
          <Stack.Screen name="SigninWithPhone" component={SetUserWithPhone} />
          <Stack.Screen
            name="CreateUserWithPhone"
            component={SetUserWithPhone}
          />
        </>
      </Stack.Navigator>
    </NavigationContainer>
  );
}
