import styles from "../../components/Stylesheet";
import React, { useEffect, useRef, useState } from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  Text,
  Image,
  Alert,
  Platform,
} from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view'
import { Formik } from "formik";
import * as firebase from "firebase";
import { useNavigation } from "@react-navigation/native";
import { useDispatch, useStore } from "react-redux";
import {
  updateFormSection,
  saveAndContinue,
  saveAndSignout,
} from "../../src/features/application/applicationSlice";
import { logout } from "../../src/features/user/userSlice";
import * as DocumentPicker from "expo-document-picker";

const ao_OtherDocuments = ({ navigation }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const form = store.getState().application.form;
  const uid = store.getState().user.uid;
  const nav = useNavigation();
  const sectionName = "OTHER_DOCUMENTS";
  const nextSectionRoute = "AssignmentOfBenefits";
  const formRef = useRef();
  const [POLSTURI, setPOLSTURI] = useState(
    form[sectionName] && form[sectionName]["POLSTURI"]
      ? form[sectionName]["POLSTURI"]
      : null
  );
  const [livingWillURI, setLivingWillURI] = useState(
    form[sectionName] && form[sectionName]["livingWillURI"]
      ? form[sectionName]["livingWillURI"]
      : null
  );
  const [powerOfAttorney, setPowerOfAttorneyURI] = useState(
    form[sectionName] && form[sectionName]["powerOfAttorney"]
      ? form[sectionName]["powerOfAttorney"]
      : null
  );
  const [localStatePOLSTUploaded, setLocalStatePOLSTUploaded] = useState(
    form[sectionName] && form[sectionName].POLSTURI ? true : false
    )
  const [localStateLivingWillUploaded, setLocalStateLivingWillUploaded] = useState(
    form[sectionName] && form[sectionName].livingWillURI ? true : false
    )
  const [localStatePowerOfAttUploaded, setLocalStatePowerOfAttUploaded] = useState(
    form[sectionName] && form[sectionName].powerOfAttorneyURI ? true : false
    )


  async function onPressPOLST() {
    let result = await DocumentPicker.getDocumentAsync();

    let resultURI = result.uri;

    async function uploadPOLSTfile(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const filesPath = storage.ref(`user/${uid}/files`);
      const ref = firebase.storage().ref().child(`user/${uid}/files/POLST`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("POLST")
              .getDownloadURL()
              .then(function (url) {
                setPOLSTURI(url);
              });
            setLocalStatePOLSTUploaded(true)
            Alert.alert("File Successfully Uploaded");
          } else {
            filesPath
            .child("POLST")
            .getDownloadURL()
            .then(function (url) {
              setPOLSTURI(url);
            });
            setLocalStatePOLSTUploaded(true)
            alert(`File Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your file: ", error);
          } else {
            alert("There was a problem uploading your file: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadPOLSTfile(result.uri);
    }
  }

  async function onPressLivingWill() {
    let result = await DocumentPicker.getDocumentAsync();

    let resultURI = result.uri;

    async function uploadLivingWill(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const filesPath = storage.ref(`user/${uid}/files`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/files/livingWill`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("livingWill")
              .getDownloadURL()
              .then(function (url) {
                setLivingWillURI(url);
              });
            setLocalStateLivingWillUploaded(true)
            Alert.alert("File Successfully Uploaded");
          } else {
            filesPath
            .child("livingWill")
            .getDownloadURL()
            .then(function (url) {
              setLivingWillURI(url);
            });
            setLocalStateLivingWillUploaded(true)
            alert(`File Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your file: ", error);
          } else {
            alert("There was a problem uploading your file: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadLivingWill(result.uri);
    }
  }
  async function onPressPowerOfAttorney() {
    let result = await DocumentPicker.getDocumentAsync();

    let resultURI = result.uri;

    async function uploadPowerOfAttorney(uri) {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storage = firebase.storage();
      const filesPath = storage.ref(`user/${uid}/files`);
      const ref = firebase
        .storage()
        .ref()
        .child(`user/${uid}/files/PowerOfAttorney`);

      await ref
        .put(blob)
        .then(() => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            imagesPath
              .child("PowerOfAttorney")
              .getDownloadURL()
              .then(function (url) {
                setPowerOfAttorneyURI(url);
              });
            setLocalStatePowerOfAttUploaded(true)
            Alert.alert("File Successfully Uploaded");
          } else {
            filesPath
            .child("PowerOfAttorney")
            .getDownloadURL()
            .then(function (url) {
              setPowerOfAttorneyURI(url);
            });
            setLocalStatePowerOfAttUploaded(true)
            alert(`File Successfully Uploaded`);
          }
        })
        .catch((error) => {
          if (Platform.OS === "ios" || Platform.OS === "android") {
            Alert.alert("There was a problem uploading your file: ", error);
          } else {
            alert("There was a problem uploading your file: ", error);
          }
        });
    }
    if (!result.canceled) {
      uploadPowerOfAttorney(result.uri);
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView>
        <View>
          <Text style={styles.sectionHeader}>Personal Documents</Text>
          <Text style={styles.sectionDescription}>
            Use the buttons below to upload the applicable documents if you have
            them.
          </Text>
        </View>
        <Formik
          innerRef={formRef}
          initialValues={{
            POLSTURI: POLSTURI,
            livingWillURI: livingWillURI,
            powerOfAttorney: powerOfAttorney,
          }}
          onSubmit={(values) => {
            dispatch(
              updateFormSection({
                [sectionName]: {
                  POLSTURI: POLSTURI,
                  livingWillURI: livingWillURI,
                  powerOfAttorney: powerOfAttorney,
                },
              })
            );
            dispatch(
              saveAndContinue(store.getState().application.form)
            );
            navigation.navigate(nextSectionRoute);
          }}
        >
          {({
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
            onSubmit,
            values,
          }) => (
            <>
              <View style={styles.fieldInputArea}>
                <View style={styles.fieldInputArea}>
                  <View style={styles.imageUploadHeading}>
                    <Text style={styles.fieldHeading}>Upload POLST</Text>
                    <Pressable onPress={onPressPOLST}>
                      <Image
                        style={styles.uploadButton}
                        source={localStatePOLSTUploaded ? require("../../assets/uploaded.png") : require("../../assets/upload.png")}
                      />
                    </Pressable>
                  </View>
                </View>
                <View style={styles.fieldInputArea}>
                  <View style={styles.imageUploadHeading}>
                    <Text style={styles.fieldHeading}>Upload Living Will</Text>
                    <Pressable onPress={onPressLivingWill}>
                      <Image
                        style={styles.uploadButton}
                        source={localStateLivingWillUploaded ? require("../../assets/uploaded.png") : require("../../assets/upload.png")}
                      />
                    </Pressable>
                  </View>
                </View>
                <View style={styles.fieldInputArea}>
                  <View style={styles.imageUploadHeading}>
                    <Text style={styles.fieldHeading}>
                      Upload Healthcare Power of Attorney
                    </Text>
                    <Pressable onPress={onPressPowerOfAttorney}>
                      <Image
                        style={styles.uploadButton}
                        source={localStatePowerOfAttUploaded ? require("../../assets/uploaded.png") : require("../../assets/upload.png")}
                      />
                    </Pressable>
                  </View>
                </View>
              </View>
              <View>
                <View>
                  <Pressable
                    style={styles.basicButton}
                    onPress={() => {
                      submitForm()
                      navigation.navigate(nextSectionRoute);
                    }}
                  >
                    <Text style={styles.basicButtonText}>Continue</Text>
                  </Pressable>
                </View>
              </View>
              <View>
                <Pressable
                  style={styles.basicButton}
                  onPress={() => {
                    dispatch(
                      updateFormSection({
                        [sectionName]: {
                          POLSTURI: POLSTURI,
                          livingWillURI: livingWillURI,
                          powerOfAttorney: powerOfAttorney,
                        },
                      })
                    );
                    dispatch(
                      saveAndSignout(store.getState().application.form)
                    );
                    dispatch(logout());
                  }}
                >
                  <Text style={styles.basicButtonText}>Save and Exit</Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

export default ao_OtherDocuments;
